import ODLogo from "@/assets/Odience-logo_nav-white.svg";
import VerseLogo from "@/assets/onboardingScreen/verse-images-optimized/verse-logo.svg";
import { DeleteOverlayButtonCSS } from "@/pages/conversationsScreen/ConversationScreen.style";
import { colors } from "@/styles/global.styles";
import Color from "color";
import { useEffect } from "react";

export default function ActiveTabPopup({
  setCurrentTabActive,
}: {
  setCurrentTabActive: () => void;
}) {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        setCurrentTabActive();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div
      css={{
        backgroundColor: "rgba(0, 14, 26, 0.8)",
        backdropFilter: "blur(10px)",
        width: "100%",
        height: "100%",
        zIndex: 9999,
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        css={{
          width: "25vw",
          minWidth: "200px",
          height: "fit-content",
          backgroundColor: colors.primaryBackground,
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1em",
          color: "white",
          padding: "2em",
        }}
      >
        <div
          css={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <img src={VerseLogo} alt="Verse" css={{ height: "3vh" }} />
          <span>+</span>

          <div
            css={{
              color: colors.primaryTextColor,
              fontFamily: "Figtree, sans-serif",
              fontWeight: "lighter",
              alignSelf: "center",
              display: "flex",
              gap: "0.5vw",
              fontSize: "1.5rem",
              alignItems: "center",
            }}
          >
            <img src={ODLogo} css={{ height: "3vh" }} alt="Odience Logo" />
            ODIENCE
          </div>
        </div>

        <h3 css={{ width: "100%" }}>Use Verse Messages here?</h3>
        <p>
          Verse Messages is open in another tab or window. Do you wish to
          continue using Verse Messages here instead?
        </p>
        <button
          css={[
            DeleteOverlayButtonCSS,
            {
              backgroundColor: colors.tertiaryAccentColor,
              color: colors.primaryTextColor,
              borderRadius: "8px",
              width: "100%",
              padding: "1.5vh 1vw ",

              ":hover": {
                backgroundColor: new Color(colors.tertiaryAccentColor)
                  .darken(0.1)
                  .toString(),
              },
            },
          ]}
          onClick={setCurrentTabActive}
        >
          Continue using Verse Messages here
        </button>
      </div>
    </div>
  );
}
