import { ChatMessage } from "@/components/chatScreen/chat/typings";
import { paths } from "@/routerPaths";
import { Imdn, ImdnInfo, OmaNmsSchema } from "@/types/OmaNms";
import {
  GroupChatInfos,
  GroupChatParticipant,
  PushMessageResult,
} from "@/types/messaging";
import { proxyToRaw } from "@/utils/";
import { insertCallFromNms } from "@/utils/calls/callUtils";
import WebGwContact, {
  getLoadedContacts,
  WebGwContactList,
} from "@/utils/helpers/WebGwContact";
import { atoms } from "@/utils/helpers/atoms";
import { ls } from "@/utils/helpers/localstorage";
import {
  isNmsNotificationsPaused,
  nmsNotificationsQueue,
} from "@/utils/helpers/nmsWebsocket";
import {
  ChatMessageStatusNotification,
  ComposingNotification,
  GroupChatIconNotification,
  NewGroupChatInvitationNotification,
  NewMessageNotification,
} from "@/utils/helpers/notificationChannel";
import { getWebRTC } from "@/utils/webrtc/webrtcUtils";
import { getDefaultStore } from "jotai";
import { getRemoteInfosFromImdnResourceUrl, sendMessageStatus } from "../..";
import NmsMessage from "../../NmsMessage";
import Conversation, { ConversationGroupChatInfos } from "../Conversation";
import { conversationsState } from "../ConversationState";
import { findConversationByPhoneNumber } from "../findConversationByPhoneNumber";
import { getTextAfterLastSlash, isSamePhoneNumber } from "./phoneNumberUtils";
import { updateConversationInDatabase } from "./updateConversationInDatabase";

const THUMBNAIL_SIZE = 100;

async function addMessageOrCreateConversation({
  phoneNumber,
  associatedContact,
  message,
  ignoreIdSet,
  contacts,
  conversation = undefined,
}: {
  phoneNumber: string;
  associatedContact?: WebGwContact;
  message: NmsMessage;
  ignoreIdSet: Set<string>;
  contacts: WebGwContactList;
  conversation?: Conversation;
}) {
  let isNew: boolean;
  let updateConversationInDb: Promise<void> | undefined;

  if (conversation) {
    isNew = false;
  } else if (message.isGroupChatInfos()) {
    ({ conversation, isNew } = insertOrUpdateGroupChatInfos(
      phoneNumber,
      message["ConferenceUri"]!,
      message.getGroupChatInfos()!,
      contacts,
      false
    ));
  } else {
    const initialMessage = message;
    if (initialMessage?.getReactionType() === "ADD") {
      return undefined;
    }

    ({ conversation, isNew, updateConversationInDb } = Conversation.getOrCreate(
      {
        phoneNumber,
        contactToLinkIfCreate: associatedContact,
        initialMessage,
      }
    ));
  }

  if (updateConversationInDb) {
    await updateConversationInDb;
  }

  if (!isNew && !message.isGroupChatInfos()) {
    const reactionType = message.getReactionType();

    switch (reactionType) {
      case "REMOVE":
        conversation.removeMessage(message, false);
        break;
      default:
        conversation.pushMessage(message, ignoreIdSet, false);
        break;
    }
  }
  ignoreIdSet.add(message["imdn.Message-ID"]);
  return conversation;
}

function setDeleteFlag(messages: NmsMessage[]) {
  const softDeletedMessageIds = new Set(
    messages
      .filter(
        (message) =>
          message["Reference-Type"] === "Delete" && message["Reference-ID"]
      )
      .map((message) => message["Reference-ID"])
  );

  for (const message of messages) {
    if (softDeletedMessageIds.has(message["imdn.Message-ID"])) {
      message.deleted = true;
    }
  }
}

export async function insertNewNmsMessagesIntoConversation(
  messages: NmsMessage[],
  contacts: WebGwContactList
) {
  setDeleteFlag(messages);

  const ignoreIdSet = new Set<string>();
  const linkedNum = new Map<string, any>();

  // assume messages are already sorted by date
  for (const message of messages) {
    const context = message["Message-Context"];

    const isGroupChat = context === "message/groupchat";

    const phoneNumber = isGroupChat
      ? message["Contribution-ID"]
      : message.Direction?.startsWith("In")
        ? message.From
        : message.To;

    if (!phoneNumber) {
      console.error(
        "Message does not have a From field or contribution id",
        message
      );
      continue;
    }

    // Ignore deleted messages
    if (
      message["Reference-Type"] === "Delete" ||
      message["Reference-Type"] === "Recall"
    ) {
      continue;
    }

    if (
      context === "message/onetoone" ||
      context === "message/chatbot" ||
      isGroupChat
    ) {
      linkedNum.set(
        phoneNumber,
        await addMessageOrCreateConversation({
          phoneNumber,
          associatedContact: isGroupChat
            ? undefined
            : contacts.findWithNumber(phoneNumber),
          ignoreIdSet,
          message,
          contacts,
          conversation: linkedNum.get(phoneNumber),
        })
      );
    } else if (context === "message/callhistory") {
      // Ignore call logs
    } else {
      console.error(`Unimplemented message context: '${context}'`, message);
    }
  }
}

export async function writeMessageToSelectedConversation(
  msg: Omit<ChatMessage, "status">,
  chatbotResponse = true
) {
  if (!msg.textMessage?.trim()) {
    console.error("Unimplemented: Message must have textMessage property", msg);
    return;
  }

  const conversation = conversationsState.selectedConversationId
    ? conversationsState.conversations.get(
        conversationsState.selectedConversationId
      )
    : undefined;
  if (!conversation) {
    throw new Error("No conversation selected");
  }

  await conversation.sendTextMessage(msg.textMessage, chatbotResponse);
}

function setImdn(
  type: ImdnInfo["type"],
  messageId: string,
  remote: string,
  date: Date,
  updateDatabase: boolean
) {
  if (remote == "" || remote.match("tel:.*[^0-9]") != null) return false;
  return setConversationMessagePartial({
    msgId: messageId,
    newMessage: {
      uploadProgress: undefined,
      imdns: {
        imdn: [
          {
            imdnInfo: [
              {
                type,
                date: date.toISOString(),
              },
            ],
            originalTo: remote,
          },
        ],
      },
    },
    conversationId: Conversation.getOrCreate({ phoneNumber: remote })
      .conversation.id,
    updateDatabase,
  });
}

export function setConversationMessageAsFailed(
  messageId: string,
  remote: string,
  date: Date,
  updateDatabase = true,
  deleteFromDatabase = false
) {
  if (deleteFromDatabase) {
    Conversation.getOrCreate({
      phoneNumber: remote,
    }).conversation.deleteMessage(messageId);
    return true;
  } else {
    return setImdn("Failed", messageId, remote, date, updateDatabase);
  }
}

export function setConversationMessageAsSent(
  messageId: string,
  remote: string,
  date: Date,
  updateDatabase = true
) {
  return setImdn("stored", messageId, remote, date, updateDatabase);
}

export function setConversationMessagePartial({
  msgId,
  newMessage,
  conversationId,
  updateDatabase = true,
  removeFields = [],
  matchOriginalMessage = true,
  discardOldMessageId = true,
}: {
  msgId: string;
  newMessage: Partial<NmsMessage>;
  conversationId: string;
  updateDatabase?: boolean;
  removeFields?: string[];
  matchOriginalMessage?: boolean;
  discardOldMessageId?: boolean;
}) {
  const conversation = conversationsState.conversations.get(conversationId);
  if (!conversation) {
    return false;
  }
  const message = conversation.getMessage(msgId, matchOriginalMessage);
  if (!message) {
    return false;
  }
  message.merge(newMessage);
  for (const i of removeFields) delete message[i];
  // In case of a new message id, we need to discard the old one in the conversation
  if (newMessage["imdn.Message-ID"]) {
    conversation.replaceMessageId(
      msgId,
      message["imdn.Message-ID"],
      discardOldMessageId
    );
  }

  if (updateDatabase) {
    updateConversationInDatabase(conversation.id);
  }
  return true;
}
const defaultStore = getDefaultStore();

export async function handleNewNmsObject(nmsNotification: OmaNmsSchema) {
  if (isNmsNotificationsPaused) {
    console.log(
      "NMS notifications are currently paused, queueing event ",
      nmsNotification
    );
    nmsNotificationsQueue.push(nmsNotification);
    return;
  }

  const nmsMessages = await NmsMessage.fromNmsWebsocketEvent(nmsNotification);
  if (!nmsMessages) return;
  for (const nmsMessage of nmsMessages) {
    if (nmsMessage.getCallLog()) {
      if (insertCallFromNms(nmsMessage)) {
        // This means it is a call log NmsObject.
        getWebRTC()?.receivedCallLogNmsObject(nmsMessage);
      }
    } else if (nmsMessage.isGroupChatInfos()) {
      const contributionId = nmsMessage["Contribution-ID"];
      const conferenceUri = nmsMessage["ConferenceUri"];

      const groupInfos = nmsMessage.getGroupChatInfos();

      if (!contributionId || !groupInfos || !conferenceUri) {
        console.log(
          "Missing contribution id or conference infos or conference uri"
        );
        continue;
      }

      insertOrUpdateGroupChatInfos(
        contributionId,
        conferenceUri,
        groupInfos,
        getLoadedContacts()
      );
    } else {
      const senderAddress = nmsMessage.getSenderAddress();
      const contributionId = nmsMessage["Contribution-ID"];

      let contactToLinkIfCreate: WebGwContact | undefined = undefined;

      if (!nmsMessage.isGroupChat()) {
        contactToLinkIfCreate = WebGwContact.fromPhoneNumber(
          senderAddress,
          true
        );
      }

      const { conversation } = Conversation.getOrCreate({
        phoneNumber: contributionId || senderAddress,
        contactToLinkIfCreate,
      });

      const reactionType = nmsMessage.getReactionType();

      if (reactionType) {
        if (reactionType === "ADD") {
          const res = conversation.pushMessage(nmsMessage);
          if (typeof res === "object") {
            displayNewIncomingMessageNotification(
              contributionId || senderAddress
            );
          }
        } else {
          conversation.removeMessage(nmsMessage);
        }
      } else {
        // Nms message is by definition a already stored message on the server, but sometimes imdn is not coming through, adding it by default here.
        if (nmsMessage.imdns?.imdn?.length === 0) {
          nmsMessage.imdns.imdn = [
            {
              imdnInfo: [
                {
                  type: "stored",
                  date: new Date().toISOString(),
                },
              ],
              originalTo: senderAddress,
            },
          ];
        }

        const alreadyRead = nmsMessage.isDisplayedNotificationSent;

        // Push message always return false if message already exists, we need to go through if message was read to potentially not show the notification
        if (
          (conversation.pushMessage(nmsMessage) || alreadyRead) &&
          nmsMessage.Direction === "In"
        ) {
          // Message was already read from another device, no need for notification
          if (alreadyRead) {
            // Clear out any pending notification
            if (pendingNotifications[senderAddress]) {
              clearTimeout(pendingNotifications[senderAddress]);
              pendingNotifications[senderAddress] = undefined;
            }
            continue;
          }

          // Wait for potential read flag from another device to not show the notification
          pendingNotifications[senderAddress] = setTimeout(() => {
            displayNewIncomingMessageNotification(
              contributionId || senderAddress
            );
          }, 1000);
        }
      }
    }
  }
}

const insertOrUpdateGroupChatInfos = (
  contributionId: string,
  conferenceUri: string,
  groupChatInfos: GroupChatInfos,
  allUserContacts?: WebGwContactList | null,
  updateDatabase = true
): { conversation: Conversation; isNew: boolean } => {
  let contactAdminId: string | undefined;
  let isLocalUserAdmin = false;
  let participants: [WebGwContact, ...WebGwContact[]] | undefined;
  const localPhoneNumber = ls.getUser();

  if (groupChatInfos.participants) {
    participants = groupChatInfos.participants
      // We dont keep the local user in the participant list
      .filter((number) => {
        if (isSamePhoneNumber(number.phoneNumber, localPhoneNumber)) {
          isLocalUserAdmin = number.isAdmin;
          return false;
        }

        return true;
      })
      .map((number) => {
        const contact =
          allUserContacts?.findWithNumber(number.phoneNumber) ??
          WebGwContact.fromPhoneNumber(number.phoneNumber)!;

        if (number.isAdmin) {
          contactAdminId = contact.id;
        }

        return contact;
      }) as [WebGwContact, ...WebGwContact[]];
  }

  const conversationGroupChatInfos: ConversationGroupChatInfos = {
    contributionId,
    conferenceUri,
    subject: groupChatInfos.subject,
    iconUrl: groupChatInfos.iconUrl,
    // This is always true, because this method is always called based on a network event (recognized us as part of the group) or the local (can update group only if still joined)
    isLocalUserJoined: true,
    isLocalUserAdmin,
    contactAdminId,
    participants,
    date: groupChatInfos.date,
  };

  const { conversation, isNew } = Conversation.getOrCreate({
    phoneNumber: contributionId,
    contactToLinkIfCreate: participants,
    groupChatInfos: conversationGroupChatInfos,
  });

  // Updating existing conversation with group chat information
  if (!isNew) {
    conversation.updateGroupInformation(
      conversationGroupChatInfos,
      updateDatabase
    );
  }

  return {
    conversation,
    isNew,
  };
};

export const getMainFileUrl = (payload: string) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(payload, "application/xml");
  const fileElement = xmlDoc.querySelector('file-info[type="file"]');
  return fileElement?.querySelector("data")?.getAttribute("url");
};

export const getMainFilePayload = (message: NmsMessage) => {
  if (!message.payloadParts || message.payloadParts.length === 0) {
    return;
  }

  // Main payload has file (webgw) or attachment (nms)
  return message.payloadParts.find(
    (p) =>
      p.contentDisposition === "file" ||
      p.contentDisposition?.startsWith("attachment")
  );
};

export function handleNewChatMessage(chatNotification: NewMessageNotification) {
  const newMessage = NmsMessage.fromWebgwNotification(chatNotification);

  if (newMessage.getCallLog()) {
    insertCallFromNms(newMessage);
  } else {
    const { senderAddress } = chatNotification;
    const remote = newMessage["Contribution-ID"] || senderAddress;

    let contactToLinkIfCreate: WebGwContact | undefined = undefined;

    if (!newMessage["Contribution-ID"]) {
      contactToLinkIfCreate = WebGwContact.fromPhoneNumber(senderAddress, true);
    }

    const { conversation, isNew } = Conversation.getOrCreate({
      phoneNumber: remote,
      initialMessage: newMessage,
      contactToLinkIfCreate,
    });

    const reactionType = newMessage.getReactionType();

    if (reactionType) {
      if (reactionType === "ADD") {
        const res = conversation.pushMessage(newMessage);
        if (typeof res === "object") {
          displayNewIncomingMessageNotification(remote);
        }
      } else {
        conversation.removeMessage(newMessage);
      }
    } else {
      sendMessageStatus(
        remote,
        newMessage["imdn.Message-ID"],
        "Delivered"
      ).then((delivered) => {
        console.debug(
          "send message status result",
          delivered ? "delivered" : "failed"
        );
      });
      newMessage.setImdn("Delivered");

      let displayNotification: PushMessageResult = true;

      if (!isNew) {
        console.log("Pushing new message into conversation:", conversation.id);
        displayNotification = conversation.pushMessage(newMessage);

        // remove composer since the message was sent
        // TODO check if this is what should be done
        const composer = conversation.composers.find(
          ([composer]) => !!composer.filterContactOnPhone(senderAddress)
        );
        if (composer) {
          conversation.removeComposer(composer[0]);
        }
      }

      if (displayNotification) {
        displayNewIncomingMessageNotification(remote);
      }
    }
  }
}

export function handleNewGroupChatInvitation(
  groupChatInvitationNotification: NewGroupChatInvitationNotification
) {
  const contactsFromInvitation =
    groupChatInvitationNotification.invite_received.split(",");

  if (contactsFromInvitation.length === 0) {
    console.warn("Group chat invitation with no participants, ignoring.");
    return;
  }

  const localUser = ls.getUser();
  const participants: GroupChatParticipant[] = contactsFromInvitation.map(
    (phoneNumber) => {
      return {
        phoneNumber,
        isJoined: true,
        isAdmin: isSamePhoneNumber(
          phoneNumber,
          groupChatInvitationNotification.admin
        ),
      };
    }
  );

  if (localUser) {
    participants.push({
      phoneNumber: localUser,
      isJoined: true,
      isAdmin: isSamePhoneNumber(
        localUser,
        groupChatInvitationNotification.admin
      ),
    });
  }

  const groupInfos: GroupChatInfos = {
    iconUrl: groupChatInvitationNotification.icon,
    subject: groupChatInvitationNotification.subject,
    participants,
  };

  insertOrUpdateGroupChatInfos(
    groupChatInvitationNotification.group_id,
    "",
    groupInfos,
    getLoadedContacts()
  );
}

export function handleGroupChatIcon(
  groupChatNotification: GroupChatIconNotification
) {
  const groupInfos: GroupChatInfos = {
    iconUrl: groupChatNotification.icon,
  };

  insertOrUpdateGroupChatInfos(groupChatNotification.group_id, "", groupInfos);
}

/**
 * Display a chat screen overlay
 * @param senderAddress The sender address of the conversation
 * @returns
 */
export const displayChatScreenOverlay = (senderAddress: string) => {
  defaultStore.set(atoms.messaging.overlayRemote, senderAddress);
};

/**
 * App will disable incoming chat notification for the specified address.
 * Can only be set for a single address.
 * @param address the address
 * @param disable true to disable the notification
 */
export const disableIncomingNotificationForRemote = (
  address: string,
  disable = true
) => {
  defaultStore.set(
    atoms.messageNotification.disabledRemote,
    disable ? address : ""
  );
};

/**
 * Completely disable incoming notification
 */
export const disableIncomingNotification = (disable = true) => {
  defaultStore.set(atoms.messageNotification.enabled, !disable);
};

export const isIncomingNotificationEnabled = () => {
  const enabled = defaultStore.get(atoms.messageNotification.enabled);

  if (!enabled) {
    console.log("Incoming notification are currently disabled");
  }

  return enabled;
};

export const isIncomingNotificationEnabledForRemote = (address: string) => {
  const addressDisabled = defaultStore.get(
    atoms.messageNotification.disabledRemote
  );
  const disabled =
    addressDisabled && isSamePhoneNumber(addressDisabled, address);

  if (disabled) {
    console.log(`Incoming notification are currently disabled for ${address}`);
  }

  return !disabled;
};

const pendingNotifications = {};
/**
 * Displays a new incoming message notification
 * @param senderAddress The sender address of the conversation
 * @param autoOpen true to directly open the chat screen overlay and discard the notification
 */
export const displayNewIncomingMessageNotification = (
  senderAddress: string
) => {
  // We want to display the message notification on all screens except the message section if tab active and notification not disabled
  if (
    !isIncomingNotificationEnabledForRemote(senderAddress) ||
    (!document.hidden &&
      (!isIncomingNotificationEnabled() ||
        location.href.includes(paths.messages)))
  ) {
    return;
  }

  defaultStore.set(atoms.messageNotification.remote, senderAddress);
};

export function handleIsComposing(chatNotification: ComposingNotification) {
  const contributionIdOrPhoneNumber =
    getTextAfterLastSlash(chatNotification?.link?.[0]?.href) ||
    chatNotification.senderAddress;

  findConversationByPhoneNumber(
    contributionIdOrPhoneNumber
  )?.handleComposingNotification(chatNotification);
}

export function handleMessageStatusNotification(
  chatMessageStatusNotification: ChatMessageStatusNotification["chatMessageStatusNotification"]
) {
  const href = chatMessageStatusNotification.link[0].href;
  const remoteInfos = getRemoteInfosFromImdnResourceUrl(href);
  const imdnStatus = chatMessageStatusNotification.status;

  if (!remoteInfos || !imdnStatus) {
    console.error(
      "Invalid chatMessageStatusNotification",
      chatMessageStatusNotification
    );
    return;
  }

  setConversationMessagePartial({
    msgId: remoteInfos.messageId,
    newMessage: {
      imdns: {
        imdn: [
          {
            imdnInfo: [
              {
                type: imdnStatus,
                date: new Date().toISOString(),
              },
            ],
            originalTo: remoteInfos.phoneNumber,
          },
        ],
      },
    },
    conversationId: Conversation.getOrCreate({
      phoneNumber: remoteInfos.contributionId || remoteInfos.phoneNumber,
    }).conversation.id,
  });
}

export async function updateConversationsInDatabase(conversationIds: string[]) {
  const tx = (await window.getVerseDb()).transaction(
    "conversations",
    "readwrite"
  );

  for (const conversationId of conversationIds) {
    const conversation = conversationsState.conversations.get(conversationId);

    if (!conversation) {
      continue;
    }

    await tx.store.put(
      proxyToRaw(proxyToRaw(conversation.serialize())),
      conversationId
    );
  }

  await tx.done;
}

const THUMBNAIL_MAX_WIDTH = 300;
const THUMBNAIL_MAX_HEIGHT = 300;

export function createThumbnail(file: Blob): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const image = new Image();

    // Read the file as DataURL
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target?.result) return reject(file);
      image.src = e.target.result as string;
    };
    reader.onerror = (_) => reject(file);
    reader.readAsDataURL(file);

    image.onerror = (_) => reject(file);
    image.onload = () => {
      const canvas = document.createElement("canvas");
      let { width, height } = image;

      if (width > height) {
        if (width > THUMBNAIL_MAX_WIDTH) {
          height *= THUMBNAIL_MAX_WIDTH / width;
          width = THUMBNAIL_MAX_WIDTH;
        }
      } else {
        if (height > THUMBNAIL_MAX_HEIGHT) {
          width *= THUMBNAIL_MAX_HEIGHT / height;
          height = THUMBNAIL_MAX_HEIGHT;
        }
      }

      canvas.width = width;
      canvas.height = height;

      const context = canvas.getContext("2d");
      if (!context) return reject(file);

      context.drawImage(image, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(file);
          }
        },
        "image/jpeg",
        0.9
      );
    };
  });
}

export const fileToBase64 = async (file: Blob | null): Promise<string> => {
  if (!file) {
    return "";
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.onerror = () => {
      reader.abort();
      reject(new Error("Error reading the Blob as base64"));
    };

    reader.readAsDataURL(file);
  });
};

export const base64ToBlob = (base64: string, contentType: string) => {
  const bytes = atob(base64.split(",")[1]);
  const byteNumbers = Array.from(bytes, (byte) => byte.charCodeAt(0));

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
};

export async function blobToUint8Array(blob: Blob): Promise<Uint8Array> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result instanceof ArrayBuffer) {
        resolve(new Uint8Array(reader.result));
      }
    };
    reader.onerror = () => {
      reject(new Error("Failed to convert blob to Uint8Array"));
    };
    reader.readAsArrayBuffer(blob);
  });
}

export function formatFileSizeToHumanReadable(fileSize?: number) {
  if (!fileSize) {
    return "";
  }

  if (fileSize === 0) {
    return 0;
  }

  const kb = 1024;
  const sizes = ["B", "KB", "MB"];
  const i = Math.floor(Math.log(fileSize) / Math.log(kb));

  return parseFloat((fileSize / Math.pow(kb, i)).toFixed(2)) + " " + sizes[i];
}

export const getContactsByImdn = (
  type: ImdnInfo["type"],
  imdns: Imdn[],
  contacts: WebGwContact[]
) => {
  return imdns.flatMap((imdn) =>
    imdn.imdnInfo
      .filter((imdnInfo) => imdnInfo.type === type)
      .map((imdnInfo) => {
        return {
          contact:
            contacts.find(
              (participant) =>
                !!participant.filterContactOnPhone(imdn.originalTo)
            ) || WebGwContact.fromPhoneNumber(imdn.originalTo)!,
          phoneNumber: imdn.originalTo,
          date: imdnInfo.date,
        };
      })
  );
};

export async function failPreviousOngoingMessages() {
  for (const conversation of conversationsState.conversations.values()) {
    const messages = conversation
      .getMessages()
      .filter(
        (message) =>
          message.uploadProgress !== undefined && message.uploadProgress < 100
      );
    let updateDatabase = false;

    for (const message of messages) {
      updateDatabase = true;
      console.log("Failing message ", message["imdn.Message-ID"]);

      setConversationMessageAsFailed(
        message["imdn.Message-ID"],
        message.To,
        message.Date,
        false
      );
    }

    if (updateDatabase) {
      await updateConversationInDatabase(conversation.id);
    }
  }
}
