import { paths } from "@/routerPaths";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function generateRandomString(length: number): string {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

let isMobileCached = false;
export function isMobile() {
  isMobileCached = !!(
    isAndroid() ||
    isIOS() ||
    navigator.userAgent.match(/webOS/i) ||
    (navigator.userAgent.match(/Mac/) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i) ||
    navigator.userAgent.match(/IEMobile/i) ||
    navigator.userAgent.match(/MSIEMobile/i)
  );
  return isMobileCached;
}

let isAndroidCached = false;
export function isAndroid() {
  isAndroidCached = !!/Android/i.test(navigator.userAgent);
  return isAndroidCached;
}

let isIOSCached = false;
export function isIOS() {
  isIOSCached = !!(
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i)
  );
  return isIOSCached;
}

let isMacCached = false;
export function isMac() {
  isMacCached = !!/Macintosh/i.test(navigator.userAgent);
  return isMacCached;
}

export async function fetchWithTimeout(
  url: URL | string,
  opts: RequestInit = {},
  timeout = 30000
): Promise<Response> {
  let controller: AbortController | null = null;
  let timeoutId: NodeJS.Timeout | undefined;

  // Fallback for old environments that don't support AbortSignal.timeout
  if (AbortSignal.timeout) {
    const signal = AbortSignal.timeout(timeout);
    opts.signal = signal;
  } else {
    controller = new AbortController();
    timeoutId = setTimeout(() => controller!.abort(), timeout);
    opts.signal = controller.signal;
  }

  try {
    const response = await fetch(url, opts);
    return response;
  } catch (e: any) {
    if (e.name === "AbortError") {
      console.error("Fetch request timed out:", url);
      throw new Error("Request timed out");
    } else {
      console.error("Fetch request failed:", e);
      throw new Error(`Fetch failed: ${e.message}`);
    }
  } finally {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }
}

export const contactNameRegexPattern = /^[+#*()0-9]/;

export function delay(milliseconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export const useOnboardingNavigationAlert = () => {
  const location = useLocation();

  useEffect(() => {
    const handlePopstate = () => {
      if (
        location.pathname === paths.onboarding ||
        location.pathname === paths.provisioning
      ) {
        if (!window.confirm("Are you sure you want to leave Verse?")) {
          window.history.pushState(null, "", location.pathname);
        }
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [location]);
};

const hasNonEmptyField = (obj): boolean => {
  const nameNotEmpty = false;
  let nonEmptyField = false;

  for (const key in obj) {
    if (
      key !== "contactId" &&
      (key === "phone" ||
        key === "email" ||
        key === "address" ||
        key === "related")
    ) {
      if (Array.isArray(obj[key])) {
        for (let i = 0; i < obj[key].length; i++) {
          for (const prop in obj[key][i]) {
            console.log("PROP", prop, obj[key][i][prop]);
            if (
              Object.prototype.hasOwnProperty.call(obj[key][i], prop) &&
              prop !== "id" &&
              prop !== "type" &&
              typeof obj[key][i][prop] === "string" &&
              obj[key][i][prop].trim() !== ""
            ) {
              nonEmptyField = true;
              break;
            }
          }
        }
      }
    }
    // Check for non-empty values in company, note, or website
    if (key === "company" || key === "note" || key === "website") {
      if (typeof obj[key] === "string" && obj[key].trim() !== "") {
        nonEmptyField = true;
      }
    }
    if (key === "contactFirstName" || key === "contactLastName") {
      if (typeof obj[key] === "string" && obj[key].trim() !== "") {
        nonEmptyField = true;
      }
    }
  }

  return nameNotEmpty || nonEmptyField;
};

export const isValidContact = (contact): boolean => {
  return hasNonEmptyField(contact);
};

export function isJSON(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const androidOdienceId = "com.summit.odience.client";
const iOsOdienceId = "id1512281957";
export const odienceAndroidDesktopUrl = `https://play.google.com/store/apps/details?id=${androidOdienceId}&pli=1`;
export const odienceIOSDesktopUrl = `https://apps.apple.com/ca/app/odience/${iOsOdienceId}`;
export const odienceMetaUrl =
  "https://www.meta.com/experiences/5674672632601679/";
export const odienceAndroidUrl = `market://details?id=${androidOdienceId}`;
export const odienceIosUrl = `itms-apps://itunes.apple.com/app/${iOsOdienceId}`;

export const verseIOSDesktopUrl =
  "https://apps.apple.com/ca/app/verse-messages/id1574554520";

export const verseMacDesktopUrl =
  "https://apps.apple.com/ca/app/verse-messages-desktop/id1612507731?mt=12";

export const verseAndroidDesktopUrl =
  "https://play.google.com/store/apps/details?id=com.summit.ims.app.messaging&pli=1";

export function openOdienceDownloadAppWebPage() {
  const userAgent = navigator.userAgent;

  const link = document.createElement("a");
  link.target = "_blank";

  if (/android/i.test(userAgent)) {
    link.href = odienceAndroidUrl;
  } else {
    link.href = odienceIosUrl;
  }

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const openVerseDownloadAppAndroid = () => {
  const newWindow = window.open(
    verseAndroidDesktopUrl,
    "_blank",
    "noopener,noreferrer"
  );
  if (newWindow) newWindow.opener = null;
};

export const openVerseDownloadAppIOS = () => {
  const newWindow = window.open(
    verseIOSDesktopUrl,
    "_blank",
    "noopener,noreferrer"
  );
  if (newWindow) newWindow.opener = null;
};

export function insertOdd(arr: any[], elem: ((idx: number) => any) | object) {
  const isFunction = typeof elem === "function";
  for (let i = 1; i < arr.length; i += 2) {
    arr.splice(i, 0, isFunction ? elem(i) : elem);
  }
  return arr;
}

export async function handleAsync<T>(
  promise: Promise<T>
): Promise<[any, T | null]> {
  try {
    const result = await promise;
    return [null, result];
  } catch (error) {
    return [error, null];
  }
}

export function isSmartTVUserAgent(userAgent: string): boolean {
  return /SmartTV/i.test(userAgent);
}
