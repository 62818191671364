import { ConfigRes } from "@/types/common";
import { queryOptions } from "@tanstack/react-query";
import { baseWebGwUrl } from "..";
import { ls } from "../helpers/localstorage";
import { fetchWithTimeout } from "../helpers/Utils";
import { handleReloginMechanic } from "../hooks/useWebgwSubscription";

export const queryConfigStaleTimeMs = 60 * 60 * 1000;

export const queryConfig = queryOptions({
  staleTime: 6 * queryConfigStaleTimeMs,
  queryKey: ["config"],
  retry: 2,
  queryFn: async () => {
    const accessToken = ls.getAccessToken();

    if (!accessToken) {
      console.error("No local access token");
      return null;
    }

    const response = await fetchWithTimeout(
      new URL(
        `/client/v1/anonymous/getconfig?access_token=${accessToken}`,
        baseWebGwUrl
      ),
      { method: "POST" }
    );
    if (!response?.ok) {
      if (response?.status === 403) {
        // Do not await here, the full relogin mechanism eventually call getConfig again, this may end up in a dead lock
        handleReloginMechanic(false);
      }

      return null;
    }

    const configRes = (await response?.json()) as ConfigRes;
    return configRes.config;
  },
  refetchOnReconnect: "always",
});
