import { motion } from "motion/react";
import { ReactNode } from "react";
import { overlayTransition } from "./DraggableOverlay";

type OverlayProps = {
  children: ReactNode;
};

const PopupOverlay = ({ children }: OverlayProps) => {
  return (
    <motion.div
      css={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 999,
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={overlayTransition}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {children}
    </motion.div>
  );
};

export default PopupOverlay;
