import { resetDocumentTitle } from "@/components/DocumentTitle";
import { CategoryKey } from "@/types/odience";
import type { OdienceEvent } from "@/utils/hooks/useDirectorAuthentication";
import { AES, enc } from "crypto-js";
import { milliseconds } from "date-fns/milliseconds";
import { DEFAULT_COUNTRY } from "../constants/common";
import { UserLocationCountry } from "../map";
import { ss } from "./sessionStorage";

const get = (item: string) => {
  return localStorage.getItem(item);
};

const set = (item: string, value: string) => {
  localStorage.setItem(item, value);
};

const remove = (item: string) => {
  localStorage.removeItem(item);
};

const secretKey = "vQAw160|E]MKGN0[2U|.";

const KEYS = {
  USER_LOCATION_COUNTRY: "localUserLocationCountryKey",
  ODIENCE_USER: "odienceUser",
  FIRST_TIME_USER: "isFirstTimeUser",
  DIRECTOR_TOKEN: "directorToken",
  DIRECTOR_REFRESH_TOKEN: "directorRefreshToken",
  ACCESS_TOKEN: "accessToken",
  LOGGED_OUT_FROM_USER: "loggedOutFromUser",
  USER_FIRST_NAME: "localUserFirstName",
  USER_LAST_NAME: "localUserLastName",
  USER: "user",
  DEVICE_NAME: "deviceName",
  SIP_USER: "sipuser",
  MESSAGES_CURSOR: "messagesCursor",
  IMPI: "impi",
  SHOW_PROFILE_SCREEN: "showProfileScreenKey",
  USERNAME: "username",
  PASSWORD: "password",
  NMS_CHANNEL_URL: "nmschannelurl",
  NMS_SUBSCRIPTION_URL: "nmssubscriptionurl",
  ODIENCE_EVENTS: "odienceEvents",
  ORGANIZATION_ID: "organizationId",
  ODIENCE_SELECTED_CATEGORY: "odienceSelectedCategory",
  FORCED_SIGNEDOUT: "forcedSignedout",
} as const;

// Utility functions
function encryptItem(value: any) {
  const stringValue = value;
  const encryptedValue = AES.encrypt(stringValue, secretKey).toString();
  return encryptedValue;
}

function decryptItem(encryptedvalue: string) {
  const decryptedBytes = AES.decrypt(encryptedvalue, secretKey);
  const decryptedValue = decryptedBytes.toString(enc.Utf8);
  return decryptedValue;
}

function setLocalStorageWithExpiry(key: string, value: string, expiry: number) {
  const expiryTime = Date.now() + expiry;
  set(key, JSON.stringify({ value, expiry: expiryTime }));
}

function getLocalStorageWithExpiry(key: string) {
  const localStorageItem = get(key);
  if (localStorageItem) {
    const localStorageItemJson = JSON.parse(localStorageItem);
    if (localStorageItemJson.expiry > Date.now()) {
      return localStorageItemJson.value;
    } else {
      remove(key);
    }
  }
  return null;
}

type StorageChangeListener = (
  newValue: string | null,
  oldValue: string | null
) => void;

class LocalStorage {
  private static listeners = new Map<string, Set<StorageChangeListener>>();

  static {
    window.addEventListener("storage", (event) => {
      if (event.storageArea !== localStorage) return;

      const key = event.key;
      if (!key) return;

      const keyListeners = this.listeners.get(key);
      if (keyListeners) {
        for (const listener of keyListeners) {
          listener(event.newValue, event.oldValue);
        }
      } else {
        console.warn(`No listeners for key: ${key}`);
      }
    });
  }

  /**
   * Subscribe to changes of a specific localStorage key
   * @param key The localStorage key to listen for changes
   * @param listener Callback function that receives the new and old values
   * @returns A function to unsubscribe the listener
   */
  static subscribe(
    key: keyof typeof KEYS,
    listener: StorageChangeListener
  ): () => void {
    if (!this.listeners.has(key)) {
      this.listeners.set(key, new Set());
    }

    const keyListeners = this.listeners.get(key)!;
    keyListeners.add(listener);

    return () => {
      this.unsubscribe(key, listener);
    };
  }

  /**
   * Unsubscribe a listener from a specific localStorage key
   * @param key The localStorage key
   * @param listener The listener to remove
   * @returns True if the listener was removed, false otherwise
   */
  static unsubscribe(key: string, listener: StorageChangeListener): boolean {
    const keyListeners = this.listeners.get(key);
    if (!keyListeners) return false;

    const result = keyListeners.delete(listener);

    if (keyListeners.size === 0) {
      this.listeners.delete(key);
    }

    return result;
  }

  static getUserLocationCountry() {
    const localCountry = get(KEYS.USER_LOCATION_COUNTRY);
    if (localCountry) {
      return JSON.parse(localCountry) as UserLocationCountry;
    }
    return DEFAULT_COUNTRY;
  }

  static setUserLocationCountry(country: UserLocationCountry) {
    set(KEYS.USER_LOCATION_COUNTRY, JSON.stringify(country));
  }

  static removeUserLocationCountry() {
    remove(KEYS.USER_LOCATION_COUNTRY);
  }

  static getOdienceUser() {
    return get(KEYS.ODIENCE_USER);
  }

  static setOdienceUser(user: string) {
    set(KEYS.ODIENCE_USER, user);
  }

  static removeOdienceUser() {
    remove(KEYS.ODIENCE_USER);
  }

  static getIsFirstTimeUser() {
    return get(KEYS.FIRST_TIME_USER);
  }

  static setIsFirstTimeUser() {
    set(KEYS.FIRST_TIME_USER, "1");
  }

  static removeIsFirstTimeUser() {
    remove(KEYS.FIRST_TIME_USER);
  }

  static getDirectorToken() {
    const encryptedLocalDirectorToken = get(KEYS.DIRECTOR_TOKEN);
    if (encryptedLocalDirectorToken) {
      const decryptedLocalDirectorToken = decryptItem(
        encryptedLocalDirectorToken
      );
      return decryptedLocalDirectorToken;
    }
    return "";
  }

  static setDirectorToken(localDirectorToken: string) {
    const encryptedLocalDirectorToken = encryptItem(localDirectorToken);
    set(KEYS.DIRECTOR_TOKEN, encryptedLocalDirectorToken);
  }

  static removeDirectorToken() {
    remove(KEYS.DIRECTOR_TOKEN);
  }

  static setDirectorRefreshToken(directorRefreshToken: string) {
    const encryptedDirectorRefreshToken = encryptItem(directorRefreshToken);
    set(KEYS.DIRECTOR_REFRESH_TOKEN, encryptedDirectorRefreshToken);
  }

  static getDirectorRefreshToken(): string {
    const encryptedLocalDirectorRefreshToken = get(KEYS.DIRECTOR_REFRESH_TOKEN);
    if (encryptedLocalDirectorRefreshToken) {
      const directorRefreshToken = decryptItem(
        encryptedLocalDirectorRefreshToken
      );
      return directorRefreshToken;
    }
    return "";
  }

  static removeDirectorRefreshToken() {
    remove(KEYS.DIRECTOR_REFRESH_TOKEN);
  }

  static getAccessToken() {
    return get(KEYS.ACCESS_TOKEN);
  }

  static setAccessToken(accessToken: string) {
    set(KEYS.ACCESS_TOKEN, accessToken);
  }

  static removeAccessToken() {
    remove(KEYS.ACCESS_TOKEN);
  }

  static initReloadOnAccessTokenChange() {
    window.addEventListener("storage", (event) => {
      if (event.key === KEYS.ACCESS_TOKEN && !event.newValue) {
        this.clearAll();
        window.location.replace("");
      }
    });
  }

  static getLoggedOutFromUser() {
    return get(KEYS.LOGGED_OUT_FROM_USER);
  }

  static setLoggedOutFromUser(value: boolean) {
    set(KEYS.LOGGED_OUT_FROM_USER, value.toString());
  }

  static setUserFirstName(firstName: string) {
    set(KEYS.USER_FIRST_NAME, firstName);
  }

  static getUserFirstName() {
    return get(KEYS.USER_FIRST_NAME) ?? "";
  }

  static removeUserFirstName() {
    remove(KEYS.USER_FIRST_NAME);
  }

  static setUserLastName(lastName: string) {
    set(KEYS.USER_LAST_NAME, lastName);
  }

  static getUserLastName() {
    return get(KEYS.USER_LAST_NAME) ?? "";
  }

  static removeUserLastName() {
    remove(KEYS.USER_LAST_NAME);
  }

  static getUser() {
    return get(KEYS.USER);
  }

  static setUser(user: string) {
    set(KEYS.USER, user);
  }

  static removeUser() {
    remove(KEYS.USER);
  }

  static getDeviceName() {
    return get(KEYS.DEVICE_NAME);
  }

  static setDeviceName(deviceName: string) {
    set(KEYS.DEVICE_NAME, deviceName);
  }

  static removeDeviceName() {
    remove(KEYS.DEVICE_NAME);
  }

  static getSipUser() {
    return get(KEYS.SIP_USER);
  }

  static setSipUser(user: string) {
    set(KEYS.SIP_USER, user);
  }

  static removeSipUser() {
    remove(KEYS.SIP_USER);
  }

  static getMessagesCursor() {
    return get(KEYS.MESSAGES_CURSOR);
  }

  static setMessagesCursor(cursor: string) {
    set(KEYS.MESSAGES_CURSOR, cursor);
  }

  static removeMessagesCursor() {
    remove(KEYS.MESSAGES_CURSOR);
  }

  static setImpi(impi: string) {
    const encryptedImpi = encryptItem(impi);
    set(KEYS.IMPI, encryptedImpi);
  }

  static getImpi(): string | null {
    const encryptedImpi = get(KEYS.IMPI);
    if (encryptedImpi) {
      const decryptedImpi = decryptItem(encryptedImpi);
      return decryptedImpi;
    }
    return null;
  }

  static removeImpi() {
    remove(KEYS.IMPI);
  }

  static setShowProfileScreen(show: boolean) {
    set(KEYS.SHOW_PROFILE_SCREEN, show.toString());
  }

  static getShowProfileScreen(): boolean {
    return get(KEYS.SHOW_PROFILE_SCREEN) === "true";
  }

  static removeShowProfileScreen() {
    remove(KEYS.SHOW_PROFILE_SCREEN);
  }

  static setUsername(username: string) {
    const encryptedUsername = encryptItem(username);
    set(KEYS.USERNAME, encryptedUsername);
  }

  static getUsername(): string | null {
    const encryptedUsername = get(KEYS.USERNAME);
    if (encryptedUsername) {
      const decryptedUsername = decryptItem(encryptedUsername);
      return decryptedUsername;
    }
    return null;
  }

  static removeUsername() {
    remove(KEYS.USERNAME);
  }

  static setPassword(password: string) {
    const encryptedPassword = encryptItem(password);
    set(KEYS.PASSWORD, encryptedPassword);
  }

  static getPassword(): string | null {
    const encryptedPassword = get(KEYS.PASSWORD);
    if (encryptedPassword) {
      const decryptedPassword = decryptItem(encryptedPassword);
      return decryptedPassword;
    }
    return null;
  }

  static removePassword() {
    remove(KEYS.PASSWORD);
  }

  static setNmsChannelUrl(nmsChannelUrl: string) {
    const encryptedNmsChannelUrl = encryptItem(nmsChannelUrl);
    set(KEYS.NMS_CHANNEL_URL, encryptedNmsChannelUrl);
  }

  static getNmsChannelUrl(): string | null {
    const encryptedNmsChannelUrl = get(KEYS.NMS_CHANNEL_URL);
    if (encryptedNmsChannelUrl) {
      const decryptedNmsChannelUrl = decryptItem(encryptedNmsChannelUrl);
      return decryptedNmsChannelUrl;
    }
    return null;
  }

  static removeNmsChannelUrl() {
    remove(KEYS.NMS_CHANNEL_URL);
  }

  static setNmsSubscriptionUrl(nmsSubscriptionUrl: string) {
    const encryptedNmsSubscriptionUrl = encryptItem(nmsSubscriptionUrl);
    set(KEYS.NMS_SUBSCRIPTION_URL, encryptedNmsSubscriptionUrl);
  }

  static getNmsSubscriptionUrl(): string | null {
    const encryptedNmsSubscriptionUrl = get(KEYS.NMS_SUBSCRIPTION_URL);
    if (encryptedNmsSubscriptionUrl) {
      const decryptedNmsSubscriptionUrl = decryptItem(
        encryptedNmsSubscriptionUrl
      );
      return decryptedNmsSubscriptionUrl;
    }
    return null;
  }

  static removeNmsSubscriptionUrl() {
    remove(KEYS.NMS_SUBSCRIPTION_URL);
  }

  static setOdienceEvents(events: OdienceEvent[]) {
    setLocalStorageWithExpiry(
      KEYS.ODIENCE_EVENTS,
      JSON.stringify(events),
      milliseconds({ days: 1 })
    );
  }

  static getOdienceEvents() {
    const localEvents = getLocalStorageWithExpiry(KEYS.ODIENCE_EVENTS);
    if (localEvents) {
      return JSON.parse(localEvents) as OdienceEvent[];
    }
    return null;
  }

  static setOrganizationId(organizationId: string) {
    set(KEYS.ORGANIZATION_ID, organizationId);
  }

  static getOrganizationId() {
    return get(KEYS.ORGANIZATION_ID);
  }

  static removeOrganizationId() {
    remove(KEYS.ORGANIZATION_ID);
  }

  static setOdienceSelectedCategory(category: CategoryKey) {
    set(KEYS.ODIENCE_SELECTED_CATEGORY, category);
  }

  static getOdienceSelectedCategory(): CategoryKey {
    return (get(KEYS.ODIENCE_SELECTED_CATEGORY) || "all") as CategoryKey;
  }

  static removeOdienceSelectedCategory() {
    remove(KEYS.ODIENCE_SELECTED_CATEGORY);
  }

  static setForcedSignedOut(value: boolean) {
    set(KEYS.FORCED_SIGNEDOUT, value.toString());
  }

  static getForcedSignedOut() {
    return get(KEYS.FORCED_SIGNEDOUT);
  }

  static removeForcedSignedOut() {
    remove(KEYS.FORCED_SIGNEDOUT);
  }

  static clearAll(kickedOutByServer?: boolean) {
    const organizationId = this.getOrganizationId();

    localStorage.clear();

    if (organizationId) {
      this.setOrganizationId(organizationId);
    }

    const eventOrgId = ss.getLastGroupId();
    const eventId = ss.getLastEventId();
    sessionStorage.clear();

    // Persist previous info for redirect if user did not initiate the logout
    if (kickedOutByServer) {
      if (eventId) {
        ss.setLastEventId(eventId);
      }

      if (eventOrgId) {
        ss.setLastGroupId(eventOrgId);
      }
    }

    resetDocumentTitle();
  }
}

export const ls = LocalStorage;
