import { dispatchCallFailure } from "@/components/callOverlays/CallOverlays";
import { ls } from "../helpers/localstorage";
import {
  releaseAudioStream,
  releaseVideoCallStream,
} from "../helpers/mediaStream";
import {
  WebRTCAnswerNotification,
  WebRTCCVONotification,
  WebRTCPauseNotification,
  WebRTCStatusUpdateNotification,
} from "../helpers/notificationChannel";
import Webrtc from "./webrtc";

const LOG_PREFIX = "webrtcUtils: ";

export function handleWebRTC(
  webRTCNotification:
    | WebRTCPauseNotification
    | WebRTCStatusUpdateNotification
    | WebRTCAnswerNotification
    | WebRTCCVONotification
) {
  getWebRTC()?.handleIncomingNotification(webRTCNotification);
}

let globalWebRTC: ReturnType<typeof getWebRTC> | undefined;
export let ringBufWebRTC: ReturnType<typeof getWebRTC> | undefined;

export function getWebRTC(): Webrtc | undefined {
  if (globalWebRTC) return globalWebRTC;
  const accessToken = ls.getAccessToken();
  if (!accessToken) {
    console.error("no access token");
    return;
  }
  return (globalWebRTC = new Webrtc());
}

export function switchWebRTC(): Webrtc | undefined {
  const wrtc = ringBufWebRTC;
  ringBufWebRTC = globalWebRTC;
  globalWebRTC = wrtc;
  return getWebRTC();
}

export const CALL_STATE = {
  NoCall: "NoCall",
  Incoming: "Incoming",
  Outgoing: "Outgoing",
  Active: "Active",
  Hold: "Hold",
  Reject: "Reject",
} as const;
type CALL_STATE = (typeof CALL_STATE)[keyof typeof CALL_STATE];

export const CALL_DIRECTION = {
  Undefined: "Undefined",
  Outgoing: "Outgoing",
  Incoming: "Incoming",
} as const;
type CALL_DIRECTION = (typeof CALL_DIRECTION)[keyof typeof CALL_DIRECTION];

export const makeAudioCall = async (
  phoneNumber: string,
  getAudioStream: () => Promise<MediaStream | undefined>,
  canMakeCall: () => boolean
) => {
  console.log(LOG_PREFIX, "makeAudioCall");
  await makeCall(
    getAudioStream(),
    (streamResolved, webrtc) => {
      webrtc.makeAudioCall(phoneNumber, streamResolved);
    },
    canMakeCall
  );
};

export const makeVideoCall = async (
  phoneNumber: string,
  acquireAudioVideoStream: () => Promise<MediaStream | undefined>,
  canMakeCall: () => boolean
) => {
  console.log(LOG_PREFIX, "makeVideoCall");
  await makeCall(
    acquireAudioVideoStream(),
    (streamResolved, webrtc) => {
      webrtc.makeVideoCall(phoneNumber, streamResolved);
    },
    canMakeCall
  );
};

const makeCall = async (
  streamPromise: Promise<MediaStream | undefined>,
  makeCall: (streamResolved: MediaStream, webrtc: Webrtc) => void,
  canMakeCall: () => boolean
) => {
  let errorMessage;
  const stream = await streamPromise.catch((e) => {
    errorMessage = "error getting local media stream";
    console.error(LOG_PREFIX, "makeCall: ", errorMessage, ". Details: ", e);
  });

  if (!stream) {
    dispatchCallFailure(errorMessage);
    return;
  }

  const webrtc = getWebRTC();

  if (!webrtc) {
    const errorMessage = "WebRTC is not available";
    console.error(LOG_PREFIX, "makeCall: ", errorMessage);
    dispatchCallFailure(errorMessage);
    return;
  }

  if (!canMakeCall()) {
    console.log(
      "makeCall: cannot make call, call has been probably ended before started"
    );
    releaseVideoCallStream();
    releaseAudioStream();
    return;
  }

  makeCall(stream, webrtc);
};
