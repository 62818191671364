import { format } from "date-fns/format";
import { isSameWeek } from "date-fns/isSameWeek";
import { isSameYear } from "date-fns/isSameYear";
import { isToday } from "date-fns/isToday";
import { isYesterday } from "date-fns/isYesterday";
import React from "react";

export function convertDateToHumanReadable(date: Date, twentyFour = false) {
  const hourMinuteFormat = twentyFour ? "H:mm" : "h:mm a";

  if (isToday(date)) {
    return format(date, `'Today at' ${hourMinuteFormat}`);
  }
  if (isYesterday(date)) {
    return format(date, `'Yesterday at' ${hourMinuteFormat}`);
  }
  const now = new Date();
  if (isSameWeek(date, now)) {
    return format(date, `EEEE 'at' ${hourMinuteFormat}`);
  }
  if (isSameYear(date, now)) {
    return format(date, `EEEE, MMM do 'at' ${hourMinuteFormat}`);
  }
  return format(date, `EEEE, MMM do, yyyy 'at' ${hourMinuteFormat}`);
}

export function convertDateToHumanReadableShort(
  date: Date,
  twentyFour = false,
  returnHoursWhenToday = true
) {
  const hourMinuteFormat = twentyFour ? "H:mm" : "h:mm a";

  if (isToday(date)) {
    return returnHoursWhenToday ? format(date, hourMinuteFormat) : `Today`;
  }
  if (isYesterday(date)) {
    return `Yesterday`;
  }
  if (isSameWeek(date, new Date())) {
    return format(date, "EEEE");
  }
  if (isSameYear(date, new Date())) {
    return format(date, "MMM d");
  }
  return format(date, "dd/MM/yyyy");
}

export function accurateTimer(
  setTime: React.Dispatch<React.SetStateAction<Date>>,
  ms = 1000
) {
  // accurate time counter using delta time rather than setInterval time
  const start = Date.now();
  const interval = setInterval(() => {
    const delta = Date.now() - start;
    const date = new Date(0);
    date.setHours(delta / 1000 / 60 / 60);
    date.setMinutes((delta / 1000 / 60) % 60);
    date.setSeconds((delta / 1000) % 60);
    setTime(date);
  }, ms);

  return () => clearInterval(interval);
}

export function isLessThanMsOld(date: Date, ms: number) {
  const msDifference = date.getTime() - Date.now();
  return msDifference < ms;
}

export const formatActiveUserCountdown = (seconds: number): string => {
  const minutes: number = Math.floor(seconds / 60);
  const secs: number = seconds % 60;
  return `${minutes}:${secs.toString().padStart(2, "0")}`;
};
