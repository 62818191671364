import { baseWebGwUrl } from "../..";
import { fetchWithTimeout } from "../Utils";
import {
  getBrowserName,
  getBrowserVendor,
  getBrowserVersion,
} from "../getBrowser";
import { ls } from "../localstorage";

export async function publishCaps() {
  const accessToken = ls.getAccessToken();
  const user = ls.getUser();
  const deviceId = generateDeviceId();

  console.log("publishCaps: accessToken:", accessToken, ", user:", user);
  if (!accessToken || !user) {
    return;
  }

  const jsonNotificationListRes = await fetchWithTimeout(
    new URL(
      `/capabilitydiscovery/v1/${user}/capabilitySources/${deviceId}?access_token=${accessToken}&_t=${Date.now()}&resFormat=json`,
      baseWebGwUrl
    ),
    {
      method: "POST",
      referrerPolicy: "no-referrer-when-downgrade",
      mode: "cors",
      credentials: "include",
      cache: "no-store",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        capabilitySource: {
          serviceCapability: [
            // TODO this seems to be broken. Verse mobile says it's a non RCS contact
            { capabilityId: "StandaloneMessaging" },
            { capabilityId: "Chat" },
            { capabilityId: "IPVideoCall" },
            { capabilityId: "RCSIPVideoCall" },
            { capabilityId: "Chatbot" },
            { capabilityId: "FileTransferViaHTTP" },
            { capabilityId: "FileTransfer" },
            { capabilityId: "GeolocationPush" },
            { capabilityId: "MsgExtended" },
          ],
        },
      }),
    }
  );

  return jsonNotificationListRes?.json();
}

export function generateDeviceId(user?: string, deviceName?: string) {
  user ??= ls.getUser()!;
  deviceName ??= ls.getDeviceName()!;
  const browserName = getBrowserName(navigator.userAgent);
  const browserVendor = getBrowserVendor(navigator.userAgent);
  const browserVersion = getBrowserVersion(navigator.userAgent);
  return btoa(
    `${browserName.slice(0, 8)}${browserVendor.slice(
      0,
      8
    )}${browserVersion.slice(0, 8)}${user}${deviceName}`
  ).replace("-", "");
}
