import { getDefaultStore } from "jotai";
import { atoms, resetAtom } from "../helpers/atoms";
import WebGwContact, {
  WebGwContactList,
  wrapToWebGwContactList,
} from "../helpers/WebGwContact";
import { isSamePhoneNumber } from "../messaging/conversation/conversationUtils/phoneNumberUtils";
import { blockedContactsQuery, contactsQuery } from "../queries/contacts";
import { queryClient } from "../queryClient";

export function filterContacts(
  searchQuery: string,
  contacts: WebGwContact[] | null | undefined,
  ignoreContacts?: WebGwContact[]
) {
  if (!contacts) return [];
  const filteredContactsWithIndices = WebGwContactList.filterContacts(
    searchQuery,
    contacts
  );
  const filteredContacts = filteredContactsWithIndices.filter(
    ([contact]) =>
      !contact.isChatbot &&
      (!ignoreContacts ||
        !ignoreContacts.some(
          (ignoredContact) => ignoredContact.id === contact.id
        ))
  );
  return filteredContacts;
}

export function filterContactWithCaps(contactList?: WebGwContactList | null) {
  if (!contactList) {
    return new WebGwContactList();
  }

  return contactList.filter((contact) => contact.isVerse) as WebGwContactList;
}

export async function getContactsAsync() {
  return wrapToWebGwContactList(await queryClient.fetchQuery(contactsQuery));
}

export function setLoadedContacts(data: WebGwContactList) {
  return queryClient.setQueryData(contactsQuery.queryKey, data);
}

export async function refreshContacts() {
  await queryClient.invalidateQueries(contactsQuery);
  queryClient.removeQueries({ queryKey: ["contactPhoneNumbers"] });
  resetAtom(atoms.contacts.contactFilter);
}

// This will cause a re-render of components using the filter regardless if value has changed
export function refreshContactsFilter() {
  const defaultStore = getDefaultStore();
  const [contactFilter, _] = defaultStore.get(atoms.contacts.contactFilter);
  defaultStore.set(atoms.contacts.contactFilter, contactFilter);
}

export async function clearBlockedContactsCache() {
  await queryClient.invalidateQueries({ queryKey: ["blockedContacts"] });
}

export async function getBlockedContacts() {
  return queryClient.fetchQuery(blockedContactsQuery);
}

export async function isPhoneNumberBlocked(phoneNumber: string) {
  const blockedList = await getBlockedContacts();

  if (blockedList) {
    return (
      blockedList.findIndex((current) =>
        isSamePhoneNumber(current, phoneNumber)
      ) !== -1
    );
  }

  return false;
}
