import { ReconnectingAnimation } from "@/components/shared/Loaders/ReconnectingAnimation";
import { colors } from "@/styles/global.styles";
import toast from "react-hot-toast";
import { ls } from "../localstorage";

export const handleSessionExpired = () => {
  ls.setForcedSignedOut(true);
};

export const showSessionExpiredToast = () => {
  toast("Your session has expired, please log in again.", {
    style: {
      backgroundColor: colors.secondaryBackground,
      color: colors.primaryTextColor,
    },
    duration: 5000,
    icon: <ReconnectingAnimation />,
    id: "toastSessionExpiredId",
  });
};
