import { SendMessageType } from "../messaging";

const KEYS = {
  RELOADED_IN_LAST_10_SECONDS: "reloadedInLast10Seconds",
  LAST_GROUP_ID: "lastGroupId",
  LAST_EVENT_ID: "lastEventId",
  EMAIL: "email",
  SWIPE_TO_EXPLORE_PROMPT: "swipeToExplorePrompt",
  TAB_ID: "tabId",
  NMS_LOGGED_IN: "nmsLoggedInKey",
  INVITE: "inviteId",
  INVITED_EVENT_ID: "invitedEventId",
  LAST_MESSAGE: "lastMessage",
} as const;

const get = (item: string) => {
  return sessionStorage.getItem(item);
};

const set = (item: string, value: string) => {
  sessionStorage.setItem(item, value);
};

const remove = (item: string) => {
  sessionStorage.removeItem(item);
};

class SessionStorage {
  static setReloadedInLast10Seconds(value: Date) {
    set(KEYS.RELOADED_IN_LAST_10_SECONDS, value.toString());
  }

  static getReloadedInLast10Seconds() {
    return get(KEYS.RELOADED_IN_LAST_10_SECONDS);
  }

  static setLastGroupId(value: string) {
    set(KEYS.LAST_GROUP_ID, value);
  }

  static getLastGroupId() {
    return get(KEYS.LAST_GROUP_ID);
  }

  static removeLastGroupId() {
    remove(KEYS.LAST_GROUP_ID);
  }

  static setLastEventId(value: string) {
    set(KEYS.LAST_EVENT_ID, value);
  }

  static getLastEventId() {
    return get(KEYS.LAST_EVENT_ID);
  }

  static removeLastEventId() {
    remove(KEYS.LAST_EVENT_ID);
  }

  static setEmail(value: string) {
    set(KEYS.EMAIL, value);
  }

  static getEmail() {
    return get(KEYS.EMAIL);
  }

  static removeEmail() {
    remove(KEYS.EMAIL);
  }

  static setInvite(value: string) {
    set(KEYS.INVITE, value);
  }

  static getInvite() {
    return get(KEYS.INVITE);
  }

  static removeInvite() {
    remove(KEYS.INVITE);
  }

  static setInvitedEventId(value: string) {
    set(KEYS.INVITED_EVENT_ID, value);
  }

  static getInvitedEventId() {
    return get(KEYS.INVITED_EVENT_ID);
  }

  static removeInvitedEventId() {
    remove(KEYS.INVITED_EVENT_ID);
  }

  static setSwipeToExplorePrompt(value: string) {
    set(KEYS.SWIPE_TO_EXPLORE_PROMPT, value);
  }

  static getSwipeToExplorePrompt() {
    return get(KEYS.SWIPE_TO_EXPLORE_PROMPT);
  }

  static setTabId(value: string) {
    set(KEYS.TAB_ID, value);
  }

  static getTabId() {
    return get(KEYS.TAB_ID);
  }

  static removeTabId() {
    remove(KEYS.TAB_ID);
  }

  static setNmsLoggedInKey(value: string) {
    set(KEYS.NMS_LOGGED_IN, value);
  }

  static getNmsLoggedInKey() {
    return get(KEYS.NMS_LOGGED_IN);
  }

  static removeNmsLoggedInKey() {
    remove(KEYS.NMS_LOGGED_IN);
  }

  static setLastMessage(value: SendMessageType) {
    set(KEYS.LAST_MESSAGE, JSON.stringify(value));
  }

  static getLastMessage() {
    const value = get(KEYS.LAST_MESSAGE);
    return value ? (JSON.parse(value) as SendMessageType) : null;
  }

  static removeLastMessage() {
    remove(KEYS.LAST_MESSAGE);
  }
}

export const ss = SessionStorage;
