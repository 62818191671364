export const paths = {
  any: "*",
  root: "/",
  eula: "/eula",
  privacyPolicy: "/privacy-policy",
  onboarding: "/onboarding",
  provisioning: "/provisioning",
  provisioningFromOdience: `/provisioning-from-odience`,
  calls: "/calls",
  messages: "/messages",
  contacts: "/contacts",
  chatbots: "/chatbots",
  settings: "/settings",
  odience: "/odience",
  stream: "/odience/event/stream/:eventId/",
  details: "/odience/event/details/:groupId/:eventId/",
  previewOdience: "/odience-preview",
  previewOdienceDetails: "/odience-preview/event/details/:groupId/:eventId/",
  previewOdienceStream: "/odience-preview/event/stream/:eventId/",
} as const;

export const defaultPage = paths.messages;
