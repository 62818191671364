import { useEffect, useRef } from "react";
import { baseNMSUrl } from "..";
import { OmaNmsSchema } from "../../types/OmaNms";
import { isNmsLoggedIn, nmsLogin, setNmsLoggedIn } from "../messaging";
import { handleNewNmsObject } from "../messaging/conversation/conversationUtils/";
import { delay, generateRandomString } from "./Utils";
import { ls } from "./localstorage";
import {
  NmsEventNotification,
  NmsListener,
  nmsListenerType,
  notificationChannel,
} from "./notificationChannel";

// in seconds
export const NMS_CHANNEL_DURATION = 3600;
export const WS_RETRY_AFTER = 5;

/**
 * Should only be called once
 * @param getAccessToken
 * @param getUser
 * @param callbackURL
 */
export default function useNmsWebSocket() {
  const connected = useRef(false);
  useEffect(() => {
    if (connected.current) return;

    const user = ls.getUser();
    if (!user) return;

    void openNmsWebSocket(user);
    connected.current = true;
  }, []);
}

export let isNmsNotificationsPaused: boolean;
export const nmsNotificationsQueue: OmaNmsSchema[] = [];

export function useNmsNotifications(notificationHandler: NmsListener) {
  useEffect(() => {
    notificationChannel.addEventListener(nmsListenerType, notificationHandler);
    return () => {
      if (import.meta.env.DEV)
        console.debug("removing NmsNotification event listener");
      notificationChannel.removeEventListener(
        nmsListenerType,
        notificationHandler
      );
      isNmsNotificationsPaused = false;
      nmsNotificationsQueue.splice(0);
    };
  }, [notificationHandler]);
}

export function pauseNmsNotifications() {
  console.log("pauseNmsNotifications");
  isNmsNotificationsPaused = true;
}

export function resumeNmsNotifications() {
  console.log("resumeNmsNotifications");
  // Queue the resume allowing it to be run out of the flow, when sync code from callers finishes (leaving time for the database insertion);
  queueMicrotask(() => {
    isNmsNotificationsPaused = false;
    for (const event of nmsNotificationsQueue) {
      notificationChannel.dispatchEvent(
        new NmsEventNotification({ detail: event })
      );
    }
    nmsNotificationsQueue.splice(0);
  });
}

export type NmsMessageHandler = (n: OmaNmsSchema) => void;
export function useNmsObjectMessage(handler: NmsMessageHandler) {
  useNmsNotifications(({ detail: nmsRes }) => {
    console.log("nmsRes:", nmsRes);
    if ("nmsEventList" in nmsRes) {
      handler(nmsRes);
    }
  });
}

class WebSocketUtil {
  private ws: WebSocket | undefined;

  constructor(url: URL, onCloseCb: (user: string) => void, user: string) {
    this.ws = new WebSocket(url);

    this.ws.onopen = () => {
      console.log("NmsWebsocket: WebSocket connection opened");
    };

    this.ws.onmessage = (event) => {
      notificationChannel.dispatchEvent(
        new NmsEventNotification({
          detail: JSON.parse(event.data),
        })
      );
    };

    this.ws.onerror = (error) => {
      console.error("NmsWebsocket: WebSocket error:", error);
    };

    this.ws.onclose = () => {
      console.log("NmsWebsocket: WebSocket connection closed");
      this.ws = undefined;
      onCloseCb(user);
    };
  }

  sendMessage(message: string) {
    this.ws?.send(message);
  }

  close() {
    this.ws?.close();
  }

  public getWebsocket(): WebSocket | undefined {
    return this.ws;
  }
}

let intervalId: NodeJS.Timeout | undefined = undefined;
export const startInterval = (
  callback: () => void,
  intervalDuration: number
) => {
  if (intervalId !== undefined) {
    console.log("Interval is already running. Skipping setting a new one.");
    return;
  }

  console.log("Started interval for refresh notification channel");
  intervalId = setInterval(callback, intervalDuration);
};

export const stopInterval = () => {
  if (intervalId !== undefined) {
    clearInterval(intervalId);
    intervalId = undefined;
  }
};

let ws: WebSocketUtil | null = null;
export async function openNmsWebSocket(user: string) {
  console.log("ws:", ws);
  if (ws?.getWebsocket()) return;

  const channel = await createNotificationChannel(user);
  const channelUrl = channel?.notificationChannel?.channelData?.channelURL;
  const resourceURL = channel?.notificationChannel?.resourceURL;
  if (channelUrl && resourceURL) {
    console.log("NmsWebsocket: Setting NMSChannelUrl:", channelUrl);
    ls.setNmsChannelUrl(channelUrl);

    startInterval(
      () => refreshNotificationChannel(resourceURL),
      (NMS_CHANNEL_DURATION / 2) * 1000
    );

    // We have to create the websocket before subscribing to it.
    ws = new WebSocketUtil(new URL(channelUrl), restartWs, user);

    await manageNmsSubscription(user);
  }
}

const refreshNotificationChannel = async (resourceURL: string) => {
  console.log("Refreshing notification channel");
  const phoneNumber = ls.getUser();

  if (!phoneNumber) {
    console.error("No phone number provided");
    return;
  }

  await fetch(new URL(resourceURL), {
    method: "POST",
    mode: "cors",
    credentials: "include",
    cache: "no-store",
    referrerPolicy: "origin-when-cross-origin",
  });
};

async function restartWs(user: string) {
  console.log("Websocket closed, trying to restart");
  await delay(WS_RETRY_AFTER * 1000);
  await openNmsWebSocket(user);
}

async function manageNmsSubscription(user: string) {
  // removeOldSubscription();
  await openSubscription(user);
}

async function openSubscription(user: string) {
  const encodedPhoneNumber = encodeURIComponent("tel:" + user);
  const channelUrl = ls.getNmsChannelUrl();
  const match = channelUrl?.match(/\/(ch\d+)$/);
  if (match) {
    const channelId = match[1];
    console.log("NmsWebsocket: Found channelID:", channelId);
    const subResponse = await fetch(
      new URL(`/nms/v1/store/${encodedPhoneNumber}/subscriptions`, baseNMSUrl),
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          nmsSubscription: {
            callbackReference: {
              notifyURL: `${baseNMSUrl}/notificationchannel/v1/${encodedPhoneNumber}/channels/callback/${channelId}`,
            },
            clientCorrelator: generateRandomString(10),
            restartToken: `tok${Date.now()}`,
            duration: 7200,
            maxEvents: 100,
          },
        }),
      }
    );
    if (subResponse.ok) {
      const subJsonResponse = await subResponse.json();
      ls.setNmsSubscriptionUrl(subJsonResponse?.nmsSubscription?.resourceURL);
    }
  } else {
    console.warn("NmsWebsocket: Couldn't match channelID from channelUrl");
  }
}

export function useAllNmsNotifications() {
  useNmsObjectMessage(handleNewNmsObject);
}
export async function createNotificationChannel(user: string) {
  const encodedPhoneNumber = encodeURIComponent("tel:" + user);
  console.log("encodedPhoneNumber:", encodedPhoneNumber);
  if (!isNmsLoggedIn()) {
    if (await nmsLogin(encodedPhoneNumber)) {
      console.debug("Successfully logged into nms");
      setNmsLoggedIn(true);
    } else {
      console.error("Failed to log into nms");
      return;
    }
  }
  console.log("LoggedIn NMS, creating notification channel");
  const response = await fetch(
    new URL(
      `/notificationchannel/v1/${encodedPhoneNumber}/channels`,
      baseNMSUrl
    ),
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        notificationChannel: {
          clientCorrelator: `cl${user}`,
          applicationTag: "webclient",
          channelType: "WebSockets",
          channelData: {
            maxNotifications: 10,
          },
          channelLifetime: NMS_CHANNEL_DURATION,
        },
      }),
    }
  );

  if (response.ok) {
    const jsonResponse = await response.json();
    return jsonResponse;
  } else {
    console.error("Couldn't create NMS notification channel");
  }
  return "";
}
