import {
  CapabilityFetchResult,
  ServiceCapabilityArray,
} from "@/types/capabilities";
import { nanoid } from "nanoid";
import { ref } from "valtio";
import {
  chatbotToPhoneNumber,
  filterItem,
  FilterType,
  FilterTypeFields,
  getBotNameFromSip,
} from "..";
import { ChatbotInfo } from "../chatbots";
import { refreshContactsFilter } from "../contacts/contactUtils";
import {
  cleanPhoneNumber,
  isSamePhoneNumber,
} from "../messaging/conversation/conversationUtils/phoneNumberUtils";
import { contactsQuery } from "../queries/contacts";
import { queryClient } from "../queryClient";
import { getLoadedChatbots, isChatbot } from "./chatbots";
import { formatPhoneNumber } from "./formatPhoneNumber";
import {
  checkPhoneNumberCapsContactList,
  fetchCaps,
} from "./loginAndCaps/capabilities";
import { generateRandomString } from "./Utils";
import {
  attrHasArrayValue,
  ContactAttributeIndexable,
  ContactFromRes,
  ContactsResponse,
  NumberWithType,
  ODIENCE_FRONT_ROW_PHONE_INCLUDE,
  ODIENCE_VIDEO_WALL_PHONE_INCLUDE,
} from "./WebGwContactUtils";

export default interface WebGwContact extends ContactAttributeIndexable {
  [x: string]: unknown;
}
export default class WebGwContact {
  public id: string;
  public initials?: string;
  public isChatbot = false;
  public isVerse = false;
  public caps!: ServiceCapabilityArray;

  // This will be used to save the input provided by the user when looking for a contact
  public userInputNumber?: string;

  private cache!: ReturnType<typeof this.initCache>;

  constructor(contact: ContactFromRes) {
    this.construct();
    this.id = contact.contactId;

    for (const attr of contact.attributeList.attribute) {
      if (attrHasArrayValue(attr)) {
        this[attr.name] ??= [];

        if (attr.name.startsWith("phone")) {
          if (Array.isArray(attr.value)) {
            attr.value[0] = formatPhoneNumber(attr.value[0], "E123");
          } else {
            // ! supporting the old webgw format
            // @ts-expect-error
            attr.value = formatPhoneNumber(attr.value, "E123");
          }
        }

        this[attr.name]!.push(attr.value);
      } else {
        this[attr.name] = attr.value;
      }
    }

    this.setInitials();
  }

  public getName() {
    const res =
      `${this.firstName ? `${this.firstName.trim()} ` : ""}${this.lastName ? this.lastName.trim() : ""}`.trim();

    // TODO - Deprecated, fallback to name if any
    if (!res) {
      return this.name?.trim();
    }

    return res;
  }

  private construct() {
    this.isChatbot = this["phone"] ? isChatbot(this["phone"][0][0]) : false;
    this.isVerse = false;
    this.caps = [];
    this.initCache();
    this.setInitials();
    return this;
  }

  private initCache() {
    const cache = ref({
      allPhoneNumbers: null as string[] | null,
      allPhoneNumbersWithTypes: null as NumberWithType[] | null,
      refreshCapsPromise: undefined as
        | Promise<CapabilityFetchResult | undefined>
        | undefined,
    });
    this.cache = cache;
    return cache;
  }

  public async refreshCaps() {
    if (this.cache.refreshCapsPromise) return;
    const phoneNumber = this.getMainPhoneNumber();
    if (!phoneNumber) {
      console.warn("undefined phone number. Cannot refresh caps");
      return;
    }
    console.log(`[${phoneNumber}]: Refreshing caps`);
    try {
      this.cache.refreshCapsPromise = fetchCaps(phoneNumber, true);

      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      if (this.cache.refreshCapsPromise) {
        const res = await this.cache.refreshCapsPromise;
        if (res) {
          this.isVerse ||=
            res.caps?.contactServiceCapabilities.userType === "rcs";
          this.caps =
            res.caps?.contactServiceCapabilities.serviceCapability || [];
        }
      }
    } catch (e) {
      console.warn(e, this);
    } finally {
      this.cache.refreshCapsPromise = undefined;
    }
  }

  public filterContact(
    query: string,
    ...filterTypeFields: FilterTypeFields[]
  ):
    | readonly [
        WebGwContact,
        {
          nameIndex?: number | undefined;
          "email; homeIndex"?: [number, number] | undefined;
          "email; workIndex"?: [number, number] | undefined;
          "phone; mobileIndex"?: [number, number] | undefined;
          "phone; homeIndex"?: [number, number] | undefined;
          "phone; workIndex"?: [number, number] | undefined;
          phoneIndex?: number | undefined;
        },
      ]
    | undefined {
    // Nothing to filter
    if (!query) {
      return [this, {}];
    }

    // ? interesting way typescript works with `this`.
    // ? Attributes like "phone; home" which are arrays wouldn't be able to have their returned indices inferred as [number, number]
    const contact = this as WebGwContact;
    const filterOnAll =
      filterTypeFields.length === 0 ||
      filterTypeFields.includes(FilterTypeFields.ALL);
    const fields: string[] = [];

    if (filterOnAll || filterTypeFields.includes(FilterTypeFields.NAME)) {
      fields.push(
        /* TODO - Deprecated, we should only handle first and last*/ "name",
        "firstName",
        "lastName"
      );
    }

    if (filterOnAll || filterTypeFields.includes(FilterTypeFields.EMAIL)) {
      fields.push("email; home", "email; work");
    }

    if (
      filterOnAll ||
      filterTypeFields.includes(FilterTypeFields.PHONE_NUMBER)
    ) {
      fields.push("phone", "phone; home", "phone; work", "phone; mobile");
    }

    return filterItem(
      contact,
      query.toLocaleLowerCase(),
      FilterType.PARTIAL,
      ...fields
    );
  }

  public filterContactOnPhone(
    query: string,
    filterType: FilterType = FilterType.PARTIAL
  ) {
    if (!query) {
      return undefined;
    }

    const contact = this as WebGwContact;
    return filterItem(
      contact,
      cleanPhoneNumber(query),
      filterType,
      "phone",
      "phone; home",
      "phone; work",
      "phone; mobile"
    );
  }

  public getMainPhoneNumberType() {
    if (this["phone; mobile"]) {
      return "mobile";
    }
    if (this["phone; work"]) {
      return "work";
    }
    if (this["phone; home"]) {
      return "home";
    }
  }

  //TODO MAKE GET MAIN PHONENUMBER SAME and  GET MAIN PHONENUMBER WITH CAPS same function and return number with caps
  // IF A CONTACT HAS A NUMBER WITH CAPS for now now libphonenumber is complaining so made seperate until we have time to come back
  public getMainPhoneNumber() {
    return this.getAllPhoneNumbers()[0];
  }

  public getMainPhoneNumberWithCaps() {
    return this.getAllPhoneNumbersWithCaps()[0];
  }

  public getAllPhoneNumbers() {
    if (this.cache.allPhoneNumbers && this.cache.allPhoneNumbers.length > 0)
      return this.cache.allPhoneNumbers;

    const phoneNumbers: string[] = [];
    for (const phoneType of [
      "phone",
      "phone; mobile",
      "phone; work",
      "phone; home",
    ]) {
      const phoneVal = this[phoneType] as typeof this.phone;
      if (phoneVal) {
        phoneNumbers.push(...phoneVal.map(([phoneNumber]) => phoneNumber));
      }
    }

    return (this.cache.allPhoneNumbers = phoneNumbers);
  }

  public getAllPhoneNumbersWithCaps() {
    //TODO was never running function properly, need to check caching
    // if (this.cache.allPhoneNumbers) return this.cache.allPhoneNumbers;

    const phoneNumbers: string[] = [];
    for (const phoneType of [
      "phone",
      "phone; mobile",
      "phone; work",
      "phone; home",
    ]) {
      const phoneVal = this[phoneType] as typeof this.phone;
      if (phoneVal) {
        for (const phoneNumber of phoneVal) {
          if (phoneNumber[2]) {
            phoneNumbers.push(phoneNumber[0]);
          }
        }
      }
    }

    return (this.cache.allPhoneNumbers = phoneNumbers);
  }

  public async getAllPhoneNumbersWithTypesAndCaps() {
    if (this.cache.allPhoneNumbersWithTypes) {
      return this.cache.allPhoneNumbersWithTypes;
    }

    const phoneNumbers: NumberWithType[] = [];
    const phoneTypes: [phoneKey: string, label: string][] = [
      ["phone", ""],
      ["phone; mobile", "mobile"],
      ["phone; work", "work"],
      ["phone; home", "home"],
    ];

    for (const [phoneKey, label] of phoneTypes) {
      const phoneVal = this[phoneKey] as typeof this.phone;
      if (phoneVal) {
        for (const [phoneNumber] of phoneVal) {
          const res = await checkPhoneNumberCapsContactList(phoneNumber);
          const previousIsVerse = this.isVerse;
          try {
            // TODO - better to have this but check why readonly at the time of the update
            this.isVerse ||= res.isRcs;
          } catch (e) {
            console.error(e);
          }

          // Refresh the contact filter here to force a re-render of the components using it since isVerse flag may have been updated
          if (previousIsVerse !== this.isVerse) {
            refreshContactsFilter();
          }

          if (res.isRcs) {
            phoneNumbers.push([phoneNumber, label, res.caps || []]);
          }
        }
      }
    }

    this.cache.allPhoneNumbersWithTypes = phoneNumbers;
    setTimeout(() => {
      this.cache.allPhoneNumbersWithTypes = null;
    });

    return phoneNumbers;
  }

  public getAllPhoneNumbersWithTypes() {
    if (this.cache.allPhoneNumbersWithTypes) {
      return this.cache.allPhoneNumbersWithTypes;
    }

    const phoneNumbers: NumberWithType[] = [];
    const phoneTypes: [phoneKey: string, label: string][] = [
      ["phone", ""],
      ["phone; mobile", "mobile"],
      ["phone; work", "work"],
      ["phone; home", "home"],
    ];

    for (const [phoneKey, label] of phoneTypes) {
      const phoneVal = this[phoneKey] as typeof this.phone;
      if (phoneVal) {
        phoneNumbers.push(
          ...phoneVal.map(
            ([phoneNumber]: string[]) =>
              [phoneNumber, label, []] as [
                string,
                string,
                ServiceCapabilityArray,
              ]
          )
        );
      }
    }

    // cache for the current call stack
    // this method was being called many times in one component, so it can be cached
    this.cache.allPhoneNumbersWithTypes = phoneNumbers;
    setTimeout(() => {
      this.cache.allPhoneNumbersWithTypes = null;
    });

    return phoneNumbers;
  }

  // use to detect if there are multiple phone numbers
  // TODO if there are, then show a pop up to select which one to use when messaging
  public hasMultiplePhoneNumbers() {
    let hasOne = false;

    for (const [contactKey, contactVal] of Object.entries(this)) {
      if (!contactKey.startsWith("phone")) continue;

      if (contactVal) {
        if (hasOne) {
          return true;
        }
        hasOne = true;
      }
    }

    return false;
  }

  // The logic here is that if we ever were RCS, we will make voice/video/messaging possible since those SIP messages
  // will go through to the IMS anyway.

  public hasVoiceCap() {
    return this.caps.some((cap) => cap.capabilityId === "IPVoiceCall");
  }

  public hasMessagingCap() {
    return this.caps.some((cap) => cap.capabilityId === "Chat");
  }

  public hasVideoCap() {
    return this.caps.some((cap) => cap.capabilityId === "IPVideoCall");
  }

  public static fromAttributes(
    obj: ContactAttributeIndexable & {
      id?: WebGwContact["id"];
      initials?: WebGwContact["initials"];
    }
  ) {
    return WebGwContact.from(obj);
  }

  private setInitials() {
    const names = this.getName()?.split(" ");
    if (!this.initials && names && names.length >= 1) {
      if (names && names.length === 1) {
        this.initials = names[0].substring(0, 2);
      } else if (names && names.length > 1) {
        this.initials = names[0][0] + names[names.length - 1][0];
      }
    } else {
      this.initials = "";
    }
  }

  public static fromPhoneNumber(
    phoneNumber: string,
    matchKnownContacts = false
  ): WebGwContact | undefined {
    if (!phoneNumber) return undefined;

    let contact: WebGwContact | undefined = undefined;

    if (matchKnownContacts) {
      if (isChatbot(phoneNumber)) {
        const [chatbots] = getLoadedChatbots() ?? [];

        const chatbotInfo = chatbots?.find((chatbot) =>
          chatbot.id.includes(phoneNumber)
        );
        if (chatbotInfo) {
          contact = WebGwContact.fromChatbotInfo(chatbotInfo);
        }
      } else {
        contact = getLoadedContacts()?.findWithNumber(phoneNumber);
      }
    }

    if (!contact) {
      contact = WebGwContact.fromAttributes({
        id: generateRandomString(15),
        phone: [[phoneNumber, ""]],
      });

      contact.userInputNumber = formatPhoneNumber(phoneNumber, "E164");
    }

    return contact;
  }

  public static fromChatbotInfo(
    bot: Pick<
      ChatbotInfo,
      "bot_id" | "display_name" | "action_image_url" | "id"
    > &
      Partial<Pick<ChatbotInfo, "name" | "icon">>
  ) {
    // not actually a phone number, but it's what is used to communicate
    // remove the `<` and `>` from the bot id

    const chatbotPhoneNumber = chatbotToPhoneNumber(bot.bot_id || bot.id);

    const contact = WebGwContact.fromAttributes({
      id: chatbotPhoneNumber[0],
      phone: [chatbotPhoneNumber],
      firstName: bot.name || bot.display_name,
      photo: bot.icon || bot.action_image_url,
    });

    contact.isChatbot = true;

    return contact;
  }

  public static from(obj: object) {
    const contact = Object.setPrototypeOf(
      obj,
      WebGwContact.prototype
    ) as WebGwContact;
    return contact.construct();
  }

  public isOdienceVideoWall() {
    return cleanPhoneNumber(this.getMainPhoneNumber()).includes(
      ODIENCE_VIDEO_WALL_PHONE_INCLUDE
    );
  }

  public isOdienceFrontRow() {
    return cleanPhoneNumber(this.getMainPhoneNumber()).includes(
      ODIENCE_FRONT_ROW_PHONE_INCLUDE
    );
  }

  public noNameReturnPhoneNumber(
    phoneNumber?: string,
    includeFullSip: boolean = false
  ) {
    const name = this.getName();

    if ((phoneNumber || this.getMainPhoneNumber()) && !name) {
      if (this.isChatbot) {
        return getBotNameFromSip(this.getMainPhoneNumber(), includeFullSip);
      } else {
        return this.isOdienceVideoWall()
          ? "Video Wall"
          : this.isOdienceFrontRow()
            ? "Front Row"
            : formatPhoneNumber(
                // Fallback to number if format did not work
                phoneNumber || this.getMainPhoneNumber(),
                "E123"
              );
      }
    }

    // Fallback to company name
    return name || this.company;
  }
}

export class WebGwContactList extends Array<WebGwContact> {
  private static tempContacts: WebGwContact[] = [];

  constructor(contactRes?: ContactsResponse, sort = true) {
    super();
    // using the .map method on this seems to call the constructor. This is here to avoid nothing being passed to the constructor
    if (!contactRes?.contactCollection?.contact) return;
    for (const c of contactRes.contactCollection.contact) {
      if (c) this.push(new WebGwContact(c));
    }
    if (sort) {
      this.sortContacts();
    }
  }

  public static fromArray(contacts: WebGwContact[]) {
    return Object.setPrototypeOf(
      contacts,
      WebGwContactList.prototype
    ) as WebGwContactList;
  }

  public static saveTransientContact(
    phone: string,
    name: string,
    updateContactInExistingConversations: (contact: WebGwContact) => void
  ) {
    let contact = this.tempContacts.find((contact) =>
      isSamePhoneNumber(contact.getMainPhoneNumber(), phone)
    );

    if (contact) {
      contact.firstName = name;
    } else {
      contact = WebGwContact.fromAttributes({
        firstName: name,
        phone: [[phone]],
        id: nanoid(),
      });
      this.tempContacts.push(contact);
    }

    updateContactInExistingConversations(contact);
  }

  public findWithNumber(number: string) {
    const contact =
      this.findLast((contact) =>
        contact.filterContactOnPhone(
          cleanPhoneNumber(number),
          isChatbot(number) ? FilterType.PARTIAL : FilterType.PHONE_NUMBER
        )
      ) ||
      WebGwContactList.tempContacts.findLast((contact) =>
        contact.filterContactOnPhone(
          cleanPhoneNumber(number),
          isChatbot(number) ? FilterType.PARTIAL : FilterType.PHONE_NUMBER
        )
      );

    if (contact) {
      contact.userInputNumber = formatPhoneNumber(number, "E164");
    }

    return contact;
  }

  sortContacts() {
    return this.sort((a, b) => {
      const aValue = a.getName() || "";
      const bValue = b.getName() || "";
      return aValue.localeCompare(bValue);
    });
  }

  filterContacts(query: string) {
    const filteredContacts: NonNullable<
      ReturnType<(typeof WebGwContact)["prototype"]["filterContact"]>
    >[] = [];
    for (const contact of this) {
      const filteredContact = contact.filterContact(query);
      if (filteredContact) {
        filteredContacts.push(filteredContact);
      }
    }
    return filteredContacts;
  }

  public static filterContacts(query: string, contacts: WebGwContact[]) {
    const filteredContacts: NonNullable<
      ReturnType<(typeof WebGwContact)["prototype"]["filterContact"]>
    >[] = [];
    for (const contact of contacts) {
      const filteredContact = contact.filterContact(query);
      if (filteredContact) {
        filteredContacts.push(filteredContact);
      }
    }
    return filteredContacts;
  }
}

export function getLoadedContacts() {
  return wrapToWebGwContactList(
    queryClient.getQueryData(contactsQuery.queryKey)
  );
}

// The contactsQuery will lose the WebGwContactList type and just return an array of WebGwContact every time we update the atoms,
// hence causing failure everywhere where we expect WebGwContactList
export function wrapToWebGwContactList(
  webGwContacts: WebGwContactList | WebGwContact[] | null | undefined
) {
  if (!webGwContacts) {
    return webGwContacts;
  }

  if (!(webGwContacts instanceof WebGwContactList)) {
    return WebGwContactList.fromArray(webGwContacts);
  }

  return webGwContacts;
}
