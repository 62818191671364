import {
  DeleteOverlayBodyCSS,
  DeleteOverlayButtonCSS,
  DeleteOverlayCloseButtonCSS,
  MainPopupOverlayCSS,
} from "@/pages/conversationsScreen/ConversationScreen.style";
import { colors } from "@/styles/global.styles";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import PopupOverlay from "./PopupOverlay";

type ConfirmationPopupProps = {
  togglePopup: () => void;
  title: string;
  confirmationMessage: React.ReactNode;
  handleAction: (event: React.MouseEvent<HTMLButtonElement>) => void;
  primaryButtonText?: string;
  closeButtonActive?: boolean;
  secondaryButtonActive?: boolean;
  secondaryButtonText?: string;
};

const ConfirmationPopup = ({
  togglePopup,
  title,
  confirmationMessage,
  handleAction,
  primaryButtonText,
  closeButtonActive = true,
  secondaryButtonActive = true,
  secondaryButtonText,
}: ConfirmationPopupProps) => {
  const [actionDisabled, setActionDisabled] = useState(false);

  const doAction = (e) => {
    setActionDisabled(true);
    try {
      handleAction(e);
    } catch (e) {
      setActionDisabled(false);
    }
  };

  return (
    <PopupOverlay>
      <div css={MainPopupOverlayCSS}>
        <div css={DeleteOverlayBodyCSS}>
          <h3>{title}</h3>
          {closeButtonActive && (
            <button
              type="button"
              css={DeleteOverlayCloseButtonCSS}
              onClick={togglePopup}
            >
              <CloseIcon />
            </button>
          )}
        </div>

        <div css={{ marginBottom: "2vh", width: "100%", textAlign: "left" }}>
          {confirmationMessage}
        </div>

        <div
          css={{
            display: "flex",
            flexDirection: "row",
            gap: "1vw",
            justifyContent: "end",
            width: "100%",
          }}
        >
          {secondaryButtonActive && (
            <button
              type="button"
              css={[
                DeleteOverlayButtonCSS,
                {
                  backgroundColor: colors.secondaryBackground,
                  color: colors.primaryTextColor,
                },
              ]}
              onClick={togglePopup}
            >
              {secondaryButtonText || "Cancel"}
            </button>
          )}
          <button
            type="button"
            disabled={actionDisabled}
            css={[
              DeleteOverlayButtonCSS,
              {
                backgroundColor: colors.primaryAccentColor,
                color: colors.primaryTextColor,
              },
            ]}
            onClick={doAction}
          >
            {primaryButtonText || "Delete"}
          </button>
        </div>
      </div>
    </PopupOverlay>
  );
};

export default ConfirmationPopup;
