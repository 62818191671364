import { clearSelection } from "@/utils";
import { ease } from "@/utils/ease";
import {
  AnimatePresence,
  AnimationProps,
  MotionConfig,
  PanInfo,
  Point,
  motion,
  useAnimationControls,
} from "motion/react";
import { ReactNode, useLayoutEffect, useRef } from "react";
import { colors } from "../../styles/global.styles";
import WebGwContact from "../../utils/helpers/WebGwContact";

const initialOverlayPosition: { [key: string]: Point } = {};

export const overlayTransition: AnimationProps["transition"] = {
  duration: 0.25,
  ease: ease,
};

interface OverlayProps {
  isFullScreen?: boolean;
  children: ReactNode;
  contact?: WebGwContact;
  onClick?: () => void;
  onMouseMove?: () => void;
  onDoubleClick: () => void;
  onBackgroundDoubleClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  open?: boolean;
  component: "call" | "sketch";
}

export const fullscreenOverlaySize = {
  width: "var(--fullscreen-overlay-width, min(90vw, 100em))",
  height: "var(--fullscreen-overlay-height, min(90vh, 100em))",
};

export function DraggableOverlay({
  onClick,
  isFullScreen,
  children,
  onMouseMove,
  onDoubleClick,
  onBackgroundDoubleClick,
  onMouseEnter,
  onMouseLeave,
  open,
  component,
}: OverlayProps) {
  const overlayRef = useRef<HTMLDivElement>(null!);

  const outerPaddingX = 0.03;
  const outerPaddingY = 0.05;
  const altPadding = 0.007;
  const dragConstraints = {
    get left() {
      return window.innerWidth * outerPaddingX + window.innerWidth * altPadding;
    },
    get right() {
      return window.innerWidth - overlayRef.current.clientWidth - this.left;
    },
    get top() {
      return (
        window.innerHeight * outerPaddingY + window.innerWidth * altPadding
      );
    },
    get bottom() {
      return window.innerHeight - overlayRef.current.clientHeight - this.top;
    },
  };

  initialOverlayPosition[component] ??= {
    x: dragConstraints.left,
    y: dragConstraints.top,
  };

  const constrainPosition = (position: Point) => {
    position.x = Math.max(
      dragConstraints.left,
      Math.min(dragConstraints.right, position.x)
    );
    position.y = Math.max(
      dragConstraints.top,
      Math.min(dragConstraints.bottom, position.y)
    );
  };

  const animationControls = useAnimationControls();

  useLayoutEffect(() => {
    if (isFullScreen) {
      animationControls.set({
        x: "-50%",
        y: "-50%",
      });
      return;
    }
    const overlayPosition = initialOverlayPosition[component];

    const handleResize = () => {
      constrainPosition(overlayPosition);
      animationControls.set({
        x: overlayPosition.x,
        y: overlayPosition.y,
      });
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isFullScreen, open]);

  const handleDragEnd = (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) => {
    const overlayPosition = initialOverlayPosition[component];

    if (!isFullScreen) {
      overlayPosition.x += info.offset.x;
      overlayPosition.y += info.offset.y;
      constrainPosition(overlayPosition);
    }
  };

  return (
    <MotionConfig transition={overlayTransition}>
      <motion.div
        ref={overlayRef}
        animate={animationControls}
        hidden={!open}
        onClick={onClick}
        onMouseMove={onMouseMove}
        onDoubleClick={(e) => {
          e.preventDefault();
          clearSelection();
          onDoubleClick();
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        drag={!isFullScreen}
        dragConstraints={dragConstraints}
        onDragEnd={handleDragEnd}
        dragElastic={0.2}
        dragTransition={{ timeConstant: 100, power: 0.05 }}
        css={[
          {
            position: "fixed",
            backfaceVisibility: "hidden",
            zIndex: component === "call" ? "10000" : "9999",
            overflow: "hidden",
            lineHeight: "1",
          },
          isFullScreen
            ? {
                maxWidth: fullscreenOverlaySize.width,
                maxHeight: fullscreenOverlaySize.height,
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: colors.primaryBackgroundLighter,
                borderRadius: "20px",
              }
            : {
                top: "0",
                left: "0",
                cursor: "grab",
              },
        ]}
      >
        {children}
      </motion.div>
      <AnimatePresence>
        {isFullScreen && open && (
          <motion.div
            css={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              zIndex: component === "call" ? "9999" : "9998",
              backgroundColor: "rgb(0,0,0,0.75)",
            }}
            onDoubleClick={(e) => {
              e.preventDefault();
              clearSelection();
              onBackgroundDoubleClick();
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          ></motion.div>
        )}
      </AnimatePresence>
    </MotionConfig>
  );
}
