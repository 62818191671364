import { MediaExtraInfo } from "@/components/chatScreen/chat/typings/moderatorChatbotInfo";
import { AudioPlayerAPI } from "@/components/shared/AudioPlayer";
import { CallInfos } from "@/types/calling";
import { CapabilitiesPerNumber } from "@/types/capabilities";
import { DeviceIdentityData } from "@/types/common";
import { Filters } from "@/types/contacts";
import { NMSReply } from "@/types/messaging";
import { SketchInfos } from "@/types/sketch";
import { CancelTokenSource } from "axios";
import { Atom, atom, getDefaultStore, WritableAtom } from "jotai";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { atomWithReset, atomWithStorage, RESET } from "jotai/utils";
import { filterContactWithCaps } from "../contacts/contactUtils";
import Conversation from "../messaging/conversation/Conversation";
import { queryUserLocationCountry } from "../queries/common";
import { queryConfig } from "../queries/config";
import { defaultChatbotImgBackgroundColorPromise } from "./chatbots";
import {
  SketchBackgroundType,
  SketchDrawingType,
  SketchImageType,
  SketchUndoType,
} from "./notificationChannel";
import WebGwContact, { WebGwContactList } from "./WebGwContact";

function nofilterContacts(contacts: WebGwContactList | null | undefined) {
  return contacts;
}
const _contactFilter = atomWithReset<
  [
    Filters,
    (
      contacts: WebGwContactList | null | undefined
    ) => WebGwContactList | null | undefined,
  ]
>(["all", nofilterContacts]);

export const atoms = {
  noop: atom(undefined),
  calling: {
    outgoingCallInfos: atomWithReset<CallInfos | undefined>(undefined),
    callActive: atomWithReset(false),
    waitingForPermissions: atomWithReset(false),
    incomingCallInfos: atomWithReset<CallInfos | undefined>(undefined),
    callFailedError: atomWithReset<string | undefined>(undefined),
    callMuted: atomWithReset(false),
    checkAvailableHardware: atomWithReset(false),
  },
  messaging: {
    messageReply: atomWithReset<NMSReply | null>(null),
    audioPlaying: atomWithReset<AudioPlayerAPI | undefined>(undefined),
    sendBtnDisabled: atomWithReset(true),
    callTranscriptText: atomWithReset(""),
    callTranscriptRemoteSender: atomWithReset(""),
    conversationUpgradingToGroupChat: atomWithReset<
      Conversation | null | undefined
    >(null),
    inProgressFiles: atomWithReset<
      { uploadId: string; cancel: CancelTokenSource }[]
    >([]),
    overlayRemote: atomWithReset(""),
  },
  messageNotification: {
    disabledRemote: atomWithReset<string>(""),
    enabled: atomWithReset<boolean>(true),
    remote: atomWithReset(""),
  },
  sketch: {
    outgoingSketchInfos: atomWithReset<SketchInfos | undefined>(undefined),
    incomingSketchEndedInfos: atomWithReset<SketchInfos | undefined>(undefined),
    incomingSketchDrawing: atomWithReset<SketchDrawingType | undefined>(
      undefined
    ),
    incomingSketchUndo: atomWithReset<SketchUndoType | undefined>(undefined),
    incomingSketchBackground: atomWithReset<SketchBackgroundType | undefined>(
      undefined
    ),
    incomingSketchImage: atomWithReset<SketchImageType | undefined>(undefined),
    incomingSketchInfos: atomWithReset<SketchInfos | undefined>(undefined),
  },
  odience: {
    privateEventPopup: atomWithReset(false),
    webOnlyEvents: atomWithStorage("webOnlyEvents", true),
    isVideoWallCall: atomWithReset(false),
    isFrontRowCall: atomWithReset(false),
    doUsePreviewFrame: atomWithReset(""),
    featuredCaller: atomWithReset(false),
    mutedByModerator: atomWithReset(false),
    streamMicVolume: atomWithReset(0),
    chatBotMediaExtraInfo: atomWithReset<MediaExtraInfo | undefined>(undefined),
    messageToSend: atomWithReset(""),
    streamVolume: atomWithReset(100),
    previousVolume: atomWithReset<number>(100),
    streamParticipantsPhoneNumbers: atomWithReset<string[]>([]),
  },
  chatbot: {
    defaultChatbotImgBackgroundColor: atom(
      () => defaultChatbotImgBackgroundColorPromise
    ),
    selectedChatbot: atomWithReset<WebGwContact | undefined>(undefined),
  },
  user: {
    userLocationCountry: atomWithSuspenseQuery(
      (get) => queryUserLocationCountry
    ),
  },
  contacts: {
    contactFilter: atom(
      (get) => get(_contactFilter),
      (_get, set, filter: Filters | typeof RESET) => {
        switch (filter) {
          case "verse":
            set(_contactFilter, [filter, filterContactWithCaps]);
            break;
          case "all":
          case RESET:
          default:
            set(_contactFilter, ["all", nofilterContacts]);
        }
      }
    ),
    displayContactId: atomWithReset<string | null>(null),
  },
  capabilities: {
    capabilities: atomWithReset<CapabilitiesPerNumber>({}),
  },
  provisioning: {
    result: atomWithReset(false),
    user: atomWithReset(""),
    devices: atomWithReset<DeviceIdentityData[] | undefined>(undefined),
    maxDevices: atomWithReset(""),
    isLoggedIn: atomWithReset(false),
    reconnectingAtom: atomWithReset(false),
  },
  common: {
    config: atomWithSuspenseQuery((get) => queryConfig),
  },
};

const isWritableAtom = <Value, Args, Result>(
  atom: any
): atom is WritableAtom<Value, Args[], Result> =>
  atom && typeof atom.write === "function";

const defaultStore = getDefaultStore();

export const resetAtom = (atom: Atom<unknown>) => {
  if (isWritableAtom(atom)) {
    defaultStore.set(atom, RESET);
  }
};

export const resetAtoms = ({
  atomsToSelect,
  atomsToExclude,
}: {
  atomsToSelect?: { [key: string]: Atom<any> };
  atomsToExclude?: [Atom<any>];
} = {}) => {
  if (atomsToSelect) {
    console.log(
      "Resetting atoms ",
      Object.entries(atomsToSelect)
        .map((atom) => atom[0])
        .join(", ")
    );
  } else {
    console.log("Resetting all atoms");
  }

  if (atomsToExclude) {
    console.log(
      "Excluding atoms ",
      atomsToExclude.map((atom) => atom.toString()).join(", ")
    );
  }

  const toExclude = atomsToExclude
    ? atomsToExclude.map((atom) => atom.toString())
    : [];

  for (const value of (atomsToSelect
    ? Object.values(atomsToSelect)
    : Object.values(atoms).flatMap((atom) => Object.values(atom))
  ).filter((atom) => {
    return atom && isWritableAtom(atom) && !toExclude.includes(atom.toString());
  })) {
    defaultStore.set(value, RESET);
  }
};
