import { colors } from "@/styles/global.styles";
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingPortal,
  offset,
  Placement,
  shift,
  useFloating,
  useHover,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react";
import { useState, type ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type FloatingTooltipProps = {
  tooltipContent: ReactNode;
  children: ReactNode;
  placement?: Placement;
  disableFlip?: boolean;
  disableShift?: boolean;
  offsetX?: number;
  offsetY?: number;
  mountToBody?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

type XY = [string, string];
const placementOrigins: Record<Placement, XY> = {
  top: ["50%", "100%"],
  bottom: ["50%", "0%"],
  left: ["100%", "50%"],
  right: ["0%", "50%"],
  "bottom-end": ["100%", "0%"],
  "bottom-start": ["0%", "0%"],
  "top-end": ["100%", "100%"],
  "top-start": ["0%", "100%"],
  "left-end": ["100%", "0%"],
  "left-start": ["100%", "100%"],
  "right-end": ["0%", "0%"],
  "right-start": ["0%", "100%"],
};

// TODO rename this component to Tooltip after removing the old one
export default function FloatingTooltip({
  tooltipContent,
  placement = "top",
  disableFlip,
  disableShift,
  offsetX = 0,
  offsetY = 0,
  mountToBody = false,
  children,
  className,
  style,
}: FloatingTooltipProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [arrowRef, setArrowRef] = useState<SVGSVGElement | null>(null);

  const { refs, floatingStyles, context, middlewareData } = useFloating({
    open: isOpen,
    placement,
    onOpenChange: setIsOpen,
    middleware: [
      offset({
        mainAxis: offsetY + 10,
        crossAxis: offsetX,
      }),
      !disableFlip &&
        flip({
          padding: 5,
          crossAxis: placement.includes("-"),
          fallbackAxisSideDirection: "start",
        }),
      !disableShift &&
        shift({
          padding: 5,
        }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  const animationDuration = 100;
  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: "scale(0.9) translateZ(0)",
    },
    close: {
      opacity: 0,
      transform: "scale(0.9) translateZ(0)",
    },
    common: ({ placement }) => {
      const [x, y] = placementOrigins[placement];

      const shiftX = (middlewareData.shift?.x ?? 0) - 0;
      const shiftY = (middlewareData.shift?.y ?? 0) - 0;
      const res = {
        transformOrigin: `calc(${x} - ${shiftX}px) calc(${y} - ${shiftY}px)`,
      };

      return res;
    },
    duration: animationDuration,
  });

  let tooltipContentWrapper = isMounted && (
    <div
      ref={refs.setFloating}
      style={floatingStyles}
      className="z-10"
      {...getFloatingProps()}
    >
      <div
        style={{ ...transitionStyles, ...style }}
        className={twMerge(
          "z-50 rounded-md bg-primaryBackgroundLighter px-3 py-[10px] text-sm text-white shadow-md",
          className
        )}
      >
        {tooltipContent}
        <FloatingArrow
          ref={setArrowRef}
          context={context}
          fill={colors.primaryBackgroundLighter}
          tipRadius={2}
        />
      </div>
    </div>
  );

  if (isMounted && mountToBody) {
    tooltipContentWrapper = (
      <FloatingPortal>{tooltipContentWrapper}</FloatingPortal>
    );
  }

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>
      {tooltipContentWrapper}
    </>
  );
}
