import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import CryptoJS from "crypto-js";
import { getDefaultStore } from "jotai";
import mime from "mime-types";
import { baseFthttpUrl } from "..";
import { createThumbnail } from "../messaging/conversation/conversationUtils";
import NmsMessage from "../messaging/NmsMessage";
import { atoms } from "./atoms";
import { getConfig } from "./config";

const store = getDefaultStore();
const uploads = new WeakMap<
  File,
  {
    progressCallbacks: ((p: number) => void)[];
    request: Promise<AxiosResponse>;
  }
>();

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
function checkIfFunctionsAreEqual(f1: Function, f2: Function) {
  return f1.toString() === f2.toString();
}

export async function fetchWithDigestAuthentication(
  method: "POST" | "GET" | "PUT" | "DELETE",
  file: File,
  uploadThumbnail: boolean,
  /** callback with the progress of the file upload. progress of -1 indicates an error has occurred */
  progressCallback?: (progress: number) => void,
  uploadId?: string
) {
  try {
    return await _fetchWithDigestAuthentication(
      method,
      file,
      uploadThumbnail,
      progressCallback,
      uploadId
    );
  } catch (error) {
    progressCallback?.(-1);
    throw error;
  }
}

async function _fetchWithDigestAuthentication(
  method: "POST" | "GET" | "PUT" | "DELETE",
  file: File,
  uploadThumbnail: boolean,
  /** callback with the progress of the file upload. progress of -1 indicates an error has occurred */
  progressCallback?: (progress: number) => void,
  uploadId?: string
) {
  const existingUpload = uploads.get(file);
  if (existingUpload) {
    if (
      progressCallback &&
      !existingUpload.progressCallbacks.some((fn) =>
        checkIfFunctionsAreEqual(fn, progressCallback)
      )
    ) {
      uploads.set(file, {
        ...existingUpload,
        progressCallbacks: [
          ...existingUpload.progressCallbacks,
          progressCallback,
        ],
      });
    }
    return existingUpload.request;
  }

  const config = await getConfig();
  if (!config) {
    console.error("Undefined config");
    throw new Error();
  }
  console.info("Current FT-HTTP URL is :", baseFthttpUrl);
  const username = config["application/3gpp_ims/ext/gsma/username"];
  const password = config["application/3gpp_ims/ext/gsma/userpwd"];
  const url = new URL(``, baseFthttpUrl);

  const formData = new FormData();
  formData.append("file", file);

  // Add thumbnail if file is an image
  if (uploadThumbnail && file.type.startsWith("image/")) {
    const thumbnail = await createThumbnail(file);
    formData.append("thumbnail", thumbnail);
  }

  const responseAuth = await fetch(url, {
    method: method,
    mode: "cors",
    referrerPolicy: "no-referrer-when-downgrade",
    cache: "no-store",
  });

  // Extract the WWW-Authenticate header
  const wwwAuthenticateHeader = responseAuth.headers.get("WWW-Authenticate");
  if (!wwwAuthenticateHeader) {
    throw new Error("Digest authentication header not found");
  }

  const realm = /realm="(.*?)"/.exec(wwwAuthenticateHeader)![1];
  const nonce = /nonce="(.*?)"/.exec(wwwAuthenticateHeader)![1];
  const cnonce = Math.random().toString(36).substring(2, 10);

  // Create a response hash as per the Digest Authentication specification
  const HA1 = CryptoJS.MD5(`${username}:${realm}:${password}`).toString();
  const HA2 = CryptoJS.MD5(`${method}:${url.pathname}`).toString();
  const responseHash = CryptoJS.MD5(`${HA1}:${nonce}:${HA2}`).toString();

  // Construct the Authorization header
  const authHeader = `Digest username="${username}", realm="${realm}", nonce="${nonce}", uri="${url.pathname}", cnonce="${cnonce}", response="${responseHash}"`;
  const axiosApi = axios.create();

  if (progressCallback && (method === "POST" || method === "PUT")) {
    axiosApi.interceptors.request.use((config) => {
      config.onUploadProgress = (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded / (progressEvent?.total ?? 0)) * 100
        );
        for (const callback of uploads.get(file)!.progressCallbacks) {
          // oxlint-disable-next-line prefer-await-to-callbacks
          callback(progress);
        }
      };
      return config;
    });
  }

  let options: AxiosRequestConfig = {
    url: url.href,
    method,
    data: formData,
    headers: {
      Authorization: authHeader,
    },
  };

  if (uploadId) {
    const source = axios.CancelToken.source();
    const inProgressFiles = store.get(atoms.messaging.inProgressFiles);
    inProgressFiles.push({ uploadId: uploadId, cancel: source });
    store.set(atoms.messaging.inProgressFiles, inProgressFiles);

    options = { ...options, cancelToken: source.token };
  }

  const request = axiosApi.request(options);
  uploads.set(file, {
    progressCallbacks: progressCallback ? [progressCallback] : [],
    request,
  });
  return request;
}

export async function upload(file: File): Promise<string | undefined> {
  try {
    const response = await fetchWithDigestAuthentication("POST", file, false);

    if (!response || response.status < 200 || response.status > 300) {
      throw new Error();
    }

    const payload = await response.data;

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(payload, "text/xml");
    const dataElements = xmlDoc.getElementsByTagName("data");
    const urls = Array.from(dataElements).map((dataEl) =>
      dataEl.getAttribute("url")
    );

    return urls?.[0] || undefined;
  } catch (error) {
    console.error("Error uploading ", file);
  }
}

export async function download(url: string): Promise<Blob | null> {
  try {
    const res = await fetch(url);

    if (!res.ok) {
      throw new Error(`Fail to download ${url}`);
    }

    return await res.blob();
  } catch (error) {
    console.error(`Fail to download ${url}. Details:`, error);
    return null;
  }
}

export const cancel = (uploadId: string) => {
  const inProgressFiles = store.get(atoms.messaging.inProgressFiles);
  console.log("Cancelling ", uploadId);
  inProgressFiles.find((k) => k.uploadId === uploadId)?.cancel.cancel(uploadId);
};

export const getMimeType = (file: File) => {
  return file.type || mime.lookup(file.name) || "";
};

export const getMimeTypeFromBase64 = (base64: string) => {
  return base64.match(/data:(.*);base64/)?.[1] || "";
};

export function getFileContentTypeHumanReadableName(message: NmsMessage) {
  const messageContentType = message["Content-Type"];

  let contentType =
    typeof messageContentType === "string"
      ? messageContentType
      : (messageContentType?.[0] ?? "");

  contentType = contentType.includes("application/vnd.gsma.rcs-ft-http+xml")
    ? message.payloadParts[message.payloadParts.length - 1]?.contentType
    : contentType;

  switch (true) {
    case contentType.includes("audio"):
      return "an audio file";
    case contentType.includes("image"):
      return "an image";
    case contentType.includes("location"):
      return "a location";
    case contentType.includes("video"):
      return "a video";
    case contentType.includes("vcf") || contentType.includes("vcard"):
      return "a contact";
    case contentType.includes("application"):
      return "a file";
    default:
      return "";
  }
}
